import React, { FC } from 'react';
import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import { Button, Divider as _Divider, Tooltip } from 'views/components/primitive';

type Props = {
  title: string;
  className?: string;
  buttonLabel: string;
  onClickButton: () => void;
  disableAction?: boolean;
  showActionButton?: boolean;
  disableButtonTooltip?: string;
  moreActions?: React.ReactNode;
};

const ContentCard: FC<Props> = ({
  className,
  title,
  children,
  buttonLabel,
  onClickButton,
  disableAction = false,
  showActionButton = true,
  disableButtonTooltip,
  moreActions,
}) => {
  return (
    <Root className={className}>
      <CardTitle>{title}</CardTitle>
      {children}
      {disableAction && disableButtonTooltip ? (
        <>
          <Divider />
          <ButtonWrapper>
            {moreActions}
            <Tooltip tooltipText={disableButtonTooltip}>
              <DisabledButton>{buttonLabel}</DisabledButton>
            </Tooltip>
          </ButtonWrapper>
        </>
      ) : showActionButton ? (
        <>
          <Divider />
          <ButtonWrapper>
            {moreActions}
            <Button
              label={buttonLabel}
              type="primary"
              size="md"
              onClick={onClickButton}
              disabled={disableAction}
            />
          </ButtonWrapper>
        </>
      ) : undefined}
    </Root>
  );
};

export default ContentCard;

const Root = styled('div')({
  background: palette.whitePrimary,
  borderRadius: 8,
  boxShadow: `0px 1px 9px ${palette.boxShadow}`,
  padding: '24px 24px 16px 24px',
  marginBottom: 24,
});

const CardTitle = styled('h4')({
  marginBottom: 24,
});

const Divider = styled(_Divider)({
  margin: '24px 0 16px 0',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  '& > button:first-of-type': {
    marginRight: '10px',
  },
});

const DisabledButton = styled('div')({
  height: 40,
  fontSize: '0.875rem',
  padding: '0 24px',
  [`@media ${bp.md}`]: {
    padding: '0 16px',
  },
  border: 'none',
  borderRadius: 4,
  fontWeight: 'bold',
  cursor: 'not-allowed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: `${palette.deepBlueDisable} !important`,
  background: `${palette.borderInput} !important`,
});
