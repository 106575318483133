import { ListMeta } from './../../types/common';
import { observable, action } from 'mobx';
import i18n from 'i18n';
import { Appointment, AppointmentType, CreateAppointmentData } from 'types/Appointment';
import { ErrorTypes } from 'types/common';
import { AnsweredQuestion, MessageProps } from 'types/App';
import { RootStore } from '../index';
import history from 'utility/history';
import { routes } from 'utility/constants';
import { trimSpace } from 'utility/helpers';
import { defaultAppointment } from './constants';
import AppointmentApi from 'services/AppointmentApi';
import UserAnswerApi from 'services/UserAnswerApi';
import { defaultListMeta } from 'stores/Admin/constants';

class AppointmentStore {
  private readonly appointmentApi: AppointmentApi;
  private readonly userAnswerApi: UserAnswerApi;
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public quickHearingId = '';
  @observable public errors: ErrorTypes = {};
  @observable public isConfirmModal = false;
  @observable public appointmentList: Appointment[] = [];
  @observable public appointmentMeta: ListMeta = { ...defaultListMeta };
  @observable public appointmentDetail: Appointment = { ...defaultAppointment };
  @observable public appointmentResult: AnsweredQuestion[] = [];
  @observable public quickHearingList: Appointment[] = [];
  @observable public quickHearingMeta: ListMeta = { ...defaultListMeta };

  constructor({
    rootStore,
    appointmentApi,
    userAnswerApi,
  }: {
    rootStore: RootStore;
    appointmentApi: AppointmentApi;
    userAnswerApi: UserAnswerApi;
  }) {
    this.rootStore = rootStore;
    this.appointmentApi = appointmentApi;
    this.userAnswerApi = userAnswerApi;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleConfirmModal() {
    this.isConfirmModal = !this.isConfirmModal;
  }

  // GET LIST APPOINTMENT
  @action.bound
  public async getAppointmentList(currentPage = 1, sort = 'desc', order = 'updated_at') {
    this.isLoading = true;

    const {
      appStore: { userData: { id: userId } = {}, organizationId: organization_id },
    } = this.rootStore;

    try {
      const {
        data: { appointments, total, page },
      } = await this.appointmentApi.getAppointments({ currentPage, sort, order, organization_id });
      const appointmentList = appointments.map((it: Appointment) => {
        const isDelete = !!it.user_id && it.user_id.toString() === userId?.toString();
        return { ...it, can_delete: isDelete };
      });
      this.appointmentList = appointmentList;
      this.appointmentMeta = {
        ...this.appointmentMeta,
        page,
        sort,
        order,
        total,
      };
      this.errors = {};
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  //GET QUICK HEARING LIST
  @action.bound
  public async getQuickHearingList(page = 1, sort = 'desc', order = 'updated_at') {
    this.isLoading = true;

    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const { data } = await this.appointmentApi.getAppointments({
        currentPage: page,
        sort,
        order,
        organization_id,
        type: 'quick_hearing',
      });
      this.quickHearingList = data.appointments;
      this.quickHearingMeta = { ...this.quickHearingMeta, page, sort, order, total: data.total };
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  // DELETE APPOINTMENT
  @action.bound
  public async deleteAppointment(id: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      await this.appointmentApi.deleteAppointments([id], organization_id);
      this.putFlashMessages({
        content: i18n.t('appointmentDetail.deleteSuccess'),
        status: 'success',
      });
      this.getAppointmentList(
        this.appointmentMeta.page,
        this.appointmentMeta.sort,
        this.appointmentMeta.order
      );
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  // HANDLE CHANGE ADD APPOINTMENT
  @action.bound
  public handleChangeMeta(data: Partial<Appointment>) {
    this.appointmentDetail = { ...this.appointmentDetail, ...data };
  }

  // ADD APPOINTMENT
  @action.bound
  public async dispatchAddAppointment() {
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { company_name, company_pic_name, datetime, memo, client } = this.appointmentDetail;
      const data: CreateAppointmentData = {
        company_pic_name: trimSpace(company_pic_name),
        company_name: trimSpace(company_name),
        datetime,
        quick_hearing_ids: [this.quickHearingId],
        memo: trimSpace(memo),
        client_id: client?.id,
      };
      await this.appointmentApi.createAppointment(data, organization_id);
      history.push(`${routes.salesLog}?tab=appointments`);
      this.putFlashMessages({
        content: i18n.t('createAppointment.messages.createAppointmentSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
      this.isLoading = false;
    }
  }

  // HANDLE ERROR APPOINTMENT
  @action.bound
  public handleErrorAppointment(errors: Record<string, string>) {
    this.errors = { ...errors };
  }

  // GET APPOINTMENT DETAIL
  @action.bound
  public async getAppointmentDetail(id: string, appointmentType: AppointmentType) {
    this.isLoading = true;
    const {
      appStore: { userData: { id: userId } = {}, organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.appointmentApi.getAppointmentById(id, organization_id);
      if (data.appointment_type !== appointmentType) {
        throw new Error(i18n.t('quickHearing.notFoundAppointment'));
      }
      const isDelete = !!data.user_id && data.user_id.toString() === userId?.toString();
      this.appointmentDetail = { ...data, can_delete: isDelete };
      this.quickHearingId = id;
      await this.getAppointmentResult(parseInt(id), organization_id);
    } catch (error: any) {
      const tabName = appointmentType === 'appointment' ? 'appointments' : 'quick-hearings';
      history.push(`${routes.salesLog}?tab=${tabName}`);
      const content = error?.message ?? i18n.t('quickHearing.notFoundAppointment');
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  // GET APPOINTMENT RESULT
  @action.bound
  public async getAppointmentResult(id: number, organization_id: string) {
    try {
      const { data } = await this.appointmentApi.getAppointmentResult(id, organization_id);
      this.appointmentResult = data.answered_questions;
    } catch (error: any) {
      const content = error?.message ?? i18n.t('common.notFoundResult');
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  // UPDATE APPOINTMENT DETAIL
  @action.bound
  public async dispatchUpdateAppointment(id: string) {
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      await this.appointmentApi.updateAppointment({
        appointment: this.appointmentDetail,
        id,
        organization_id,
      });
      this.getAppointmentDetail(id, 'appointment');
      this.putFlashMessages({
        content: i18n.t('appointmentDetail.updateSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetAppointmentMeta() {
    this.appointmentDetail = { ...defaultAppointment };
    this.errors = {};
  }

  @action.bound
  public resetSalesLogList() {
    this.isLoading = false;
    this.quickHearingMeta = { ...defaultListMeta };
    this.appointmentMeta = { ...defaultListMeta };
    this.quickHearingList = [];
    this.appointmentList = [];
  }

  @action.bound
  public handleChangeNote(answeredQuestionId: number, note: string, hearingId: number): void {
    this.appointmentResult = this.appointmentResult.map(ar =>
      ar.id === answeredQuestionId ? { ...ar, note } : ar
    );
    this.appointmentDetail = {
      ...this.appointmentDetail,
      hearing_sets: this.appointmentDetail.hearing_sets.map(hearingSet => {
        return hearingSet.id === hearingId
          ? {
              ...hearingSet,
              isEditingHearingDetail: true,
            }
          : hearingSet;
      }),
    };
  }

  @action.bound
  public async updateUserAnswerNote(hearingId: number): Promise<void> {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    const answers = this.appointmentResult
      .filter(answer => answer.hearing_set_id === hearingId)
      .map(answer => {
        return { id: answer.id, note: trimSpace(answer.note ?? '') };
      });
    try {
      await this.userAnswerApi.updateUserAnswerNote(
        { answered_questions: answers },
        organization_id
      );

      this.appointmentDetail = {
        ...this.appointmentDetail,
        hearing_sets: this.appointmentDetail.hearing_sets.map(hearingSet => {
          return hearingSet.id === hearingId
            ? {
                ...hearingSet,
                isEditingHearingDetail: false,
              }
            : hearingSet;
        }),
      };

      this.putFlashMessages({
        content: i18n.t('appointmentDetail.updateSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    }
  }
}

export default AppointmentStore;
