import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';
import QuestionContentSelector from './QuestionContentSelector';
import ContentUploader from './ContentUploader';
import {
  UploadVideoMeta,
  UploadImageMeta,
  ExternalVideoURLMeta,
  UploadPDFMeta,
  ContentType,
  ContentMeta,
} from 'types/App';
import { questionContentTypeOptions } from './constants';
import { ContentViewType } from 'entity/Content';
type Props = {
  questionSetContent: ContentViewType;
  contentType: ContentType;
  videoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentType: (type: ContentType, resetEditMode?: boolean) => void;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  isQuestionModalOpen: boolean;
  isProductContent: boolean;
  resetExistingContentMeta: () => void;
  editMode?: boolean;
  fileTypeError: string;
  handleFileTypeError: (fileTypeError: string) => void;
};

const UploadContent: FC<Props> = ({
  questionSetContent,
  contentType,
  videoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentType,
  handleChangeContentMeta,
  isQuestionModalOpen,
  isProductContent,
  resetExistingContentMeta,
  editMode = true,
  fileTypeError,
  handleFileTypeError,
}) => {
  const { t } = useTranslation();

  const [isCreateFromExisting, setIsCreateFromExisting] = useState(isProductContent);

  useEffect(() => setIsCreateFromExisting(!!isProductContent), [
    isProductContent,
    isQuestionModalOpen,
  ]);

  const uploadStatus =
    questionSetContent?.content_type === 'videos' ? questionSetContent.upload_status : '';

  const [videoUploadStatus, setVideoUploadStatus] = useState(uploadStatus);
  useEffect(() => {
    setVideoUploadStatus(uploadStatus);
  }, [uploadStatus]);

  const handleChangeMeta = (data: ContentMeta) => {
    handleChangeContentMeta(data);
    setVideoUploadStatus('');
  };

  const selectContentType = (type: ContentType) => {
    const { content_type } = questionSetContent;
    setIsCreateFromExisting(type === 'existing_contents');
    if (['videos', 'images', 'pdfs'].includes(content_type) && type === 'videos_images_pdfs') {
      handleChangeContentType(content_type, true);
    } else {
      handleChangeContentType(type as ContentType, true);
    }
  };

  const currentContentType = !!isCreateFromExisting
    ? 'existing_contents'
    : contentType === 'images' || contentType === 'pdfs' || contentType === 'videos'
    ? 'videos_images_pdfs'
    : contentType;

  return (
    <Root>
      <h4>{t('admin.questions.content')}</h4>
      <ContentSelector
        contentTypeList={questionContentTypeOptions}
        contentType={currentContentType}
        selectContentType={selectContentType}
        resetExistingContentMeta={resetExistingContentMeta}
        isQuestionModalOpen={isQuestionModalOpen}
      />
      <ContentUploader
        contentType={contentType}
        videoMeta={videoMeta}
        externalVideoURLMeta={externalVideoURLMeta}
        imageMeta={imageMeta}
        pdfMeta={pdfMeta}
        handleChangeContentMeta={handleChangeMeta}
        uploadStatus={videoUploadStatus}
        handleChangeContentType={handleChangeContentType}
        resetExistingContentMeta={resetExistingContentMeta}
        isCreateFromExisting={isCreateFromExisting}
        setIsCreateFromExisting={setIsCreateFromExisting}
        originalContent={questionSetContent}
        editMode={editMode}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
        isProductContent={isProductContent}
        isQuestionModalOpen={isQuestionModalOpen}
      />
    </Root>
  );
};

const Root = styled('div')({
  marginBottom: 20,
  h4: {
    marginBottom: 10,
    fontSize: 16,
    color: palette.darkMedium2,
  },
});
const ContentSelector = styled(QuestionContentSelector)({
  marginBottom: 24,
});

export default UploadContent;
