import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { EmbedContentType, EmbeddingLink } from 'types/EmbeddingLink';
import { formatTime } from 'utility/helpers';
import { bp, palette } from 'entity/createTheme';
import { Label as _Label } from '../../primitive';
import { ShowEmbedCodeButton } from 'views/components/compound';
import { EmbedCodeMeta } from 'types/EmbeddingLink';

type Props = {
  embeddingLink: EmbeddingLink;
  handleClick: () => void;
  embedCodeMeta?: EmbedCodeMeta;
  getEmbedCodeByLinkId: (id: number) => void;
  className?: string;
};

const EmbedItem: FC<Props> = ({
  embeddingLink,
  handleClick,
  className,
  getEmbedCodeByLinkId,
  embedCodeMeta,
}) => {
  const { t } = useTranslation();
  const embeddingLinkType = getEmbeddingLinkType(embeddingLink.content_type);

  return (
    <Root className={className}>
      <Thumbnail>
        {embeddingLink.thumbnail_url ? (
          <img src={embeddingLink.thumbnail_url} alt="embed item thumbnail" />
        ) : (
          <NoThumbnail>{t('embeddingLink.noVideoSelected')}</NoThumbnail>
        )}
      </Thumbnail>
      <InfoWrap>
        <EmbedItemInfo>
          <Title onClick={handleClick}>{embeddingLink.title}</Title>
          <MetaInfo>
            {t('common.createdDate')}：{formatTime(embeddingLink.created_at, 'yyyy/MM/dd')}
          </MetaInfo>
          <Label variant="secondary" size="md">
            {t(`embeddingLink.contentType.${embeddingLinkType}` as const)}
          </Label>
        </EmbedItemInfo>
        <ShowEmbedCodeButton
          embedCodeMeta={embedCodeMeta}
          getEmbedCode={() => getEmbedCodeByLinkId(embeddingLink.id)}
        />
      </InfoWrap>
    </Root>
  );
};

export default EmbedItem;

const getEmbeddingLinkType = (embedContentType: EmbedContentType) => {
  switch (embedContentType) {
    case 'embedding_video':
      return 'embeddingVideo';
    case 'embedding_interactive_hearing_set':
      return 'interactiveVideo';
    case 'playlist':
      return 'playlist';
    default:
      return 'embeddingVideo';
  }
};

const Root = styled('div')({
  display: 'flex',
  [`@media ${bp.xs}`]: {
    flexDirection: 'column',
  },
});

const InfoWrap = styled('div')({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  background: palette.whitePrimary,
  marginLeft: 24,
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [`@media ${bp.xs}`]: {
    marginLeft: 0,
  },
});

const Thumbnail = styled('div')({
  width: 184,
  minWidth: 184,
  height: 138,
  borderRadius: 4,
  overflow: 'hidden',
  border: `1px solid ${palette.borderInput}`,
  background: palette.blackPrimary,
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  [`@media ${bp.xs}`]: {
    width: '100%',
    height: 180,
  },
});

const NoThumbnail = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  background: palette.base,
  fontWeight: 600,
  fontSize: '0.875rem',
  color: palette.deepBlueLight,
});

const EmbedItemInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [`@media ${bp.xs}`]: {
    paddingLeft: 0,
    paddingTop: 12,
  },
});

const Title = styled('h5')({
  fontWeight: 800,
  fontSize: '1rem',
  lineHeight: 1.5,
  color: palette.deepBluePrimary,
  marginRight: 10,
  wordBreak: 'break-word',
  '&:hover': {
    cursor: 'pointer',
    color: palette.greenMedium1,
    transition: 'color 0.2s linear',
  },
});

const MetaInfo = styled('p')({
  fontWeight: 500,
  fontSize: 10,
  lineHeight: 1.6,
  color: palette.deepBlueLight,
  marginTop: 10,
});

const Label = styled(_Label)({
  width: 'fit-content',
  marginTop: 24,
  [`@media ${bp.md}`]: {
    marginBottom: 12,
  },
});
