import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import React, { FC } from 'react';
import { Button } from 'views/components/primitive';

type Props = {
  menuName: string;
  description?: string | React.ReactNode;
  buttonText: string;
  onClick?: () => void;
  moreActionButton?: React.ReactNode;
};

const SettingMenuItem: FC<Props> = ({
  menuName,
  description,
  buttonText,
  onClick,
  moreActionButton,
}) => {
  return (
    <Wrapper>
      <MenuName>{menuName}</MenuName>
      {description && <Description>{description}</Description>}
      <ActionButtons>
        <Button label={buttonText} onClick={onClick} type="default" size="sm" />
        {moreActionButton && moreActionButton}
      </ActionButtons>
    </Wrapper>
  );
};

export default SettingMenuItem;

const MenuName = styled('p')({
  color: palette.darkMedium2,
  fontWeight: 'bold',
  fontSize: '0.875rem',
  gridColumn: 1,
  whiteSpace: 'nowrap',
});

const Wrapper = styled('div')({
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
  background: palette.whitePrimary,
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr auto 1fr',
  padding: 16,
  borderRadius: 6,
  alignItems: 'center',
  gridGap: 12,
});

const Description = styled('div')({
  gridColumn: 2,
  fontSize: '0.875rem',
});

const ActionButtons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gridColumn: 3,
});
