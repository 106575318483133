import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingItem } from 'types/HearingSet';
import { DebouncedSearchInput, Drawer, InteractiveHearingItem } from 'views/components/compound';
import { RadioButton as _RadioButton } from 'views/components/primitive';

type Props = {
  open: boolean;
  toggleOpen: () => void;
  handleSelectInteractiveHearing: (id: number) => void;
  interactiveHearingId: number;
  interactiveHearingList: HearingItem[];
  handleSearchInteractiveHearings: (keyword: string) => void;
  isAddNewDrawerOpen: boolean;
};

const SelectInteractiveHearingDrawer: FC<Props> = ({
  open,
  toggleOpen,
  handleSelectInteractiveHearing,
  interactiveHearingId,
  interactiveHearingList,
  handleSearchInteractiveHearings,
  isAddNewDrawerOpen,
}) => {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedHearingId, setSelectedHearingId] = useState(interactiveHearingId);
  const disableAction = !selectedHearingId;

  useEffect(() => {
    !isAddNewDrawerOpen && setSearchKeyword('');
  }, [isAddNewDrawerOpen]);

  useEffect(() => setSelectedHearingId(interactiveHearingId), [interactiveHearingId]);

  const selectInteractiveHearing = () => {
    handleSelectInteractiveHearing(selectedHearingId);
    toggleOpen();
  };

  const handleSearch = () => {
    handleSearchInteractiveHearings(searchKeyword);
  };

  const handleChangeSelectedHearing = (id: number) => {
    setSelectedHearingId(id);
  };

  const handleClose = () => {
    setSelectedHearingId(interactiveHearingId);
    toggleOpen();
  };

  return (
    <Drawer
      disabledAction={disableAction}
      isOpen={open}
      title={t('embeddingLink.interactiveVideoSection.drawerTitle')}
      onClose={handleClose}
      onSave={selectInteractiveHearing}
      saveButtonLabel={t('common.select2')}
    >
      <DrawerInnerWrapper>
        <SearchArea>
          <SearchBar
            onSearch={handleSearch}
            placeholder={t('common.search')}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
        </SearchArea>
        <List className="scroll-bar-thumbnail">
          {interactiveHearingList.map(interactiveHearing => {
            return (
              <ListItem key={interactiveHearing.id}>
                <RadioButton
                  onChange={() => handleChangeSelectedHearing(interactiveHearing.id)}
                  checked={interactiveHearing.id === selectedHearingId}
                />
                <InteractiveHearingItem interactiveHearing={interactiveHearing} />
              </ListItem>
            );
          })}
        </List>
      </DrawerInnerWrapper>
    </Drawer>
  );
};

export default SelectInteractiveHearingDrawer;

const DrawerInnerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 60px)',
});

const SearchArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const SearchBar = styled(DebouncedSearchInput)({
  marginBottom: 10,
});

const ListItem = styled('div')({
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const List = styled('div')({
  overflowY: 'auto',
});

const RadioButton = styled(_RadioButton)({
  padding: 0,
  marginRight: 12,
});
