import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ProductItem } from 'types/Product';
import { ProductItemForManagement } from 'views/components/compound';
import { Label as _Label } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import { bp, palette } from 'entity/createTheme';

type Props = {
  handleSelectContent: (contentId: number, contentType?: string) => void;
  productContents: ProductItem[];
  actionButtons?: React.ReactNode;
};

const ExistingContentList: FC<Props> = ({
  handleSelectContent,
  productContents,
  actionButtons,
}) => {
  const { t } = useTranslation();

  return (
    <ListWrapper>
      {productContents.map(content => {
        const footer = (
          <CardFooter>
            <Label data-autify_selector="product_content_list_content_type_label" variant={'grey'}>
              {t(`common.contentLabelText.${content.content_type}`)}
            </Label>
            {actionButtons}
          </CardFooter>
        );
        return (
          <ProductItemForManagement
            key={content.id}
            productContent={content}
            handleClickContentCard={() =>
              handleSelectContent(content.content_id, content.content_type)
            }
            footer={footer}
            isGray
          />
        );
      })}
    </ListWrapper>
  );
};

export default ExistingContentList;

const ListWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: 20,
  marginTop: 20,
});

const CardFooter = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Label = styled(_Label)({
  width: 'fit-content',
  height: 'fit-content',
  [`@media ${bp.md}`]: {
    marginLeft: 0,
  },
  color: palette.darkMedium2,
  borderColor: palette.darkMedium2,
});
