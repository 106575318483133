import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ContentType } from 'types/App';
import { QuestionContentTypeOption } from './constants';
import { Dropdown } from 'views/components/compound';

type Props = {
  contentTypeList: QuestionContentTypeOption[];
  contentType: ContentType;
  selectContentType: (type: ContentType) => void;
  className?: string;
  resetExistingContentMeta?: () => void;
  isQuestionModalOpen: boolean;
};

const QuestionContentSelector: FC<Props> = ({
  contentTypeList,
  contentType,
  selectContentType,
  className,
  resetExistingContentMeta,
  isQuestionModalOpen,
}) => {
  const handleChangeType = (type: ContentType) => {
    selectContentType(type);
    resetExistingContentMeta && resetExistingContentMeta();
  };

  return (
    <Root className={className}>
      <Dropdown
        size="sm"
        value={contentType}
        options={contentTypeList}
        onChange={handleChangeType}
        width={216}
        isParentComponentShown={isQuestionModalOpen}
      />
    </Root>
  );
};

export default QuestionContentSelector;

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});
