import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';
import styled from '@emotion/styled';
import { Button } from 'views/components/primitive';
import { Modal } from 'views/components/compound';

interface PropsTypes {
  className?: string;
  isArchive?: boolean;
  isDelete?: boolean;
  isModal: boolean;
  toggleModal: () => void;
  item?: string;
  handleConfirmModal: () => void;
}

const ConfirmModal: FC<PropsTypes> = ({
  isModal,
  toggleModal,
  handleConfirmModal,
  isArchive,
  isDelete,
  item,
  className,
}) => {
  const { t } = useTranslation();

  function confirmModal() {
    toggleModal();
    handleConfirmModal();
  }

  return (
    <Modal
      isModal={isModal}
      handleToggle={toggleModal}
      title={
        isArchive
          ? t('admin.common.archive')
          : isDelete
          ? t('admin.common.delete')
          : t('common.cancel')
      }
    >
      <Wrapper className={className}>
        <p>
          {isArchive && !isDelete && t('admin.common.messageConfirmArchive', { item: item })}
          {!isArchive && isDelete && t('admin.common.messageConfirmDelete')}
          {!isDelete && !isArchive && t('common.confirmCancel')}
        </p>
        {(!isArchive || isDelete) && <h4>{t('common.warningUndone')}</h4>}
        <ModalAction isDeleteOrArchive={!!isDelete || !!isArchive}>
          <Button
            label={isArchive || isDelete ? t('admin.common.cancelArchive') : t('common.noText')}
            size="md"
            type="default"
            onClick={toggleModal}
          />
          <Button
            label={
              isArchive
                ? t('admin.common.confirmArchive')
                : isDelete
                ? t('admin.common.confirmDelete')
                : t('common.yesText')
            }
            className={isArchive || isDelete ? 'danger' : ''}
            size="md"
            type="primary"
            onClick={confirmModal}
          />
        </ModalAction>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;

const Wrapper = styled('div')({
  padding: '10px 32px',
  'p, h4': {
    fontSize: 16,
    textAlign: 'center',
  },
  p: {
    marginBottom: 10,
  },
  h4: {
    marginBottom: 30,
    color: palette.orangePrimary,
  },
});

const ModalAction = styled('div')<{ isDeleteOrArchive: boolean }>(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '> button': {
    width: props.isDeleteOrArchive ? 140 : 93, //modal action buttons must have same width
    '&:last-of-type': {
      marginLeft: 10,
      background: palette.orangePrimary,
      '&:hover': {
        background: palette.orangeSecondary,
      },
    },
    '.danger': {
      background: palette.redPrimary,
      '&:hover': {
        background: palette.redSecondary,
      },
    },
  },
}));
