import { useCollapseList, useStore } from 'hooks';
import useDragCursor from 'hooks/useDragCursor';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SortEnd } from 'react-sortable-hoc';
import { hearingLinkStatus } from 'utility/constants';
import arrayMove from 'array-move';
import { ProductItemForPlaylist } from 'types/Playlist';

export const useSortProductContents = (
  handleIsProductsOrderChanged: (isOrderChange: boolean) => void
) => {
  const {
    playlistDetailStore: { updatePlaylist, playlistDetail },
  } = useStore();
  const isArchived = playlistDetail.status === hearingLinkStatus.archived;
  const { id } = useParams<{ id: string }>();

  const { product_contents: originalProductContents } = playlistDetail;

  //Sort Product Contents
  const { dragging, handleDragStart, handleDragEnd } = useDragCursor();
  const [isEditMode, setEditMode] = useState(false);
  const [isConfirmQuitEditMode, setConfirmQuitEditMode] = useState(false);
  const [productContents, setProductContents] = useState<ProductItemForPlaylist[]>(
    originalProductContents
  );

  const {
    isCollapsedList,
    showAll,
    handleShowAll,
    handleCollapse,
    itemsToShow: productsToShow,
  } = useCollapseList(productContents || []);

  useEffect(() => {
    setProductContents(originalProductContents);
  }, [originalProductContents]);

  const hasDraftOrArchived =
    productContents &&
    productContents.some(
      productContent => productContent.status === 'draft' || productContent.status === 'archived'
    );

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
    !isEditMode && !showAll && handleShowAll();
  };

  const toggleConfirmQuitEditModal = () => {
    setConfirmQuitEditMode(!isConfirmQuitEditMode);
  };

  const resetProductContentsOrder = () => {
    setProductContents(originalProductContents);
    handleIsProductsOrderChanged(false);
  };

  const handleConfirmQuitEditMode = () => {
    toggleConfirmQuitEditModal();
    resetProductContentsOrder();
    setEditMode(false);
    handleCollapse();
  };

  const handleStartSortProducts = () => {
    handleDragStart();
  };

  const handleSortProductContents = (oldIndex: number, newIndex: number) => {
    if (newIndex !== oldIndex && productContents.length > 0) {
      const newContents = arrayMove(productContents, oldIndex, newIndex);
      setProductContents(newContents);
    }
    handleIsProductsOrderChanged(true);
  };

  const handleEndSortProducts = (sort: SortEnd) => {
    const { oldIndex, newIndex } = sort;
    handleDragEnd();
    handleSortProductContents(oldIndex, newIndex);
  };

  const handleClickArrow = (index: number, value: -1 | 1) => {
    const newIndex = index + value;
    if (!productContents) return;
    if (newIndex < 0 || productContents.length <= newIndex) return;
    handleSortProductContents(index, newIndex);
  };

  const saveProductsOrder = () => {
    if (!productContents) return;
    updatePlaylist(parseInt(id), {
      product_contents: productContents,
    });
    toggleEditMode();
  };

  return {
    productContents,
    hasDraftOrArchived,
    saveProductsOrder,
    isEditMode,
    toggleConfirmQuitEditModal,
    toggleEditMode,
    handleStartSortProducts,
    handleEndSortProducts,
    productsToShow,
    dragging,
    handleClickArrow,
    isCollapsedList,
    showAll,
    handleCollapse,
    handleShowAll,
    isConfirmQuitEditMode,
    handleConfirmQuitEditMode,
    isArchived,
  };
};

export const useEditProductContents = () => {
  const {
    playlistDetailStore: { updatePlaylist, playlistDetail },
    playlistStore: { idProductContent },
  } = useStore();

  const { product_contents: originalProductContents } = playlistDetail;

  const { id } = useParams<{ id: string }>();

  const [productContents, setProductContents] = useState<ProductItemForPlaylist[]>(
    originalProductContents
  );
  const [productContentIds, setProductContentIds] = useState<number[]>(
    originalProductContents.map(content => content.id)
  );

  const [isPlaylistChanged, setPlaylistChanged] = useState(false);

  useEffect(() => {
    setProductContents(originalProductContents);
    setProductContentIds(originalProductContents.map(content => content.id));
  }, [originalProductContents]);

  const handleChangeDisplayedText = (newText: string, contentId: number) => {
    const newContentList = productContents.map(content =>
      content.id === contentId ? { ...content, playlist_title: newText || null } : content
    );
    setProductContents(newContentList);
    setPlaylistChanged(true);
    return;
  };

  const deleteContent = (contentId: number) => {
    const newContentList = productContents.filter(content => content.id !== contentId);
    setProductContents(newContentList);
    setProductContentIds(productContentIds.filter(id => id !== contentId));
    setPlaylistChanged(true);
  };

  const updateSelectedContents = (contentIds: number[]) => {
    const newContentList = contentIds.map(contentId => {
      const playlistTitle =
        productContents.find(content => content.id === contentId)?.playlist_title || null;
      return {
        ...idProductContent[contentId],
        playlist_title: playlistTitle,
      };
    });
    setProductContents(newContentList);
    setProductContentIds(contentIds);
    setPlaylistChanged(true);
  };

  const handleSaveContentList = () => {
    updatePlaylist(parseInt(id), { product_contents: productContents });
    setPlaylistChanged(false);
  };

  const resetProductContents = () => {
    setProductContents(originalProductContents);
    setProductContentIds(originalProductContents.map(content => content.id));
    setPlaylistChanged(false);
  };

  return {
    productContents,
    deleteContent,
    updateSelectedContents,
    resetProductContents,
    handleChangeDisplayedText,
    productContentIds,
    handleSaveContentList,
    isPlaylistChanged,
  };
};
