import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatTime } from 'utility/helpers';
import { palette, bp } from 'entity/createTheme';
import { ProductItem as ProductItemTypes } from 'types/Product';
import { getImgFromContent } from 'utility/helpers';
import PreviewOnHover from '../PreviewOnHover';

type Props = {
  productContent: ProductItemTypes;
  handleClickContentCard: () => void;
  footer?: React.ReactNode;
  className?: string;
  isGray?: boolean;
};

const ProductItemForManagement: FC<Props> = ({
  productContent,
  handleClickContentCard,
  footer,
  className,
  isGray,
}) => {
  const { t } = useTranslation();

  return (
    <ProductItemWrapper className={className} isGray={!!isGray}>
      <ProductItemThumbnailWrapper>
        <ProductItemThumbnail>
          {productContent.content_type === 'videos' ? (
            <PreviewOnHover
              videoDuration={productContent.duration || 0}
              videoUrl={productContent.kaizen_files_url || ''}
              videoId={productContent.kaizen_files_id}
              uploadStatus={productContent.upload_status || ''}
              thumbnailUrl={getImgFromContent(productContent) || ''}
            />
          ) : (
            <img src={getImgFromContent(productContent)} alt="prod-img" />
          )}
        </ProductItemThumbnail>
      </ProductItemThumbnailWrapper>
      <ProductItemContent onClick={handleClickContentCard}>
        <ProductTitle data-autify_selector="product_content_title">
          {productContent.title}
        </ProductTitle>
        <UpdatedTime>
          {t('common.lastUpdated')}: {formatTime(productContent.updated_at, 'yyyy/MM/dd')}
        </UpdatedTime>
        {footer}
      </ProductItemContent>
    </ProductItemWrapper>
  );
};

export default ProductItemForManagement;

const ProductItemWrapper = styled('div')<{ isGray: boolean }>(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  borderRadius: 6,
  background: props.isGray ? palette.base : palette.whitePrimary,
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
  border: `1px solid ${palette.borderInput}`,
  /* hover interaction code */
  cursor: 'pointer',
  textAlign: 'start',
  minWidth: 0,
  minHeight: 0,
}));

const ProductItemThumbnailWrapper = styled('div')({
  width: '100%',
  paddingTop: '56.25%',
  position: 'relative',
});

const ProductItemThumbnail = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '6px 6px 0 0',
  overflow: 'hidden',
  background: palette.blackPrimary,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  position: 'absolute',
  top: 0,
  left: 0,
});

const ProductItemContent = styled('div')({
  padding: 16,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProductTitle = styled('p')({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  marginBottom: 10,
  cursor: 'pointer',
  height: 40,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

const UpdatedTime = styled('span')({
  fontSize: '0.5rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  color: palette.grayMedium4,
  marginBottom: 10,
  [`@media ${bp.md}`]: {
    marginBottom: 8,
  },
});
