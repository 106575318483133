import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { Checkbox } from 'views/components/primitive';
import { useLocationSearch, usePushLocationSearch } from 'hooks';

type Props = {
  className?: string;
  label: string;
};

const ToggleArchivedList: FC<Props> = ({ className, label }) => {
  const { isArchived } = useLocationSearch();
  const push = usePushLocationSearch();
  const isArchivedList = typeof isArchived === 'string' && isArchived === '1';

  const onToggleCheckbox = () => {
    isArchivedList ? push({ isArchived: 0 }) : push({ isArchived: 1 });
  };

  useEffect(() => {
    if (!isArchivedList) push({ isArchived: 0 });
  }, []);

  return (
    <Wrapper className={className}>
      <Checkbox onChange={onToggleCheckbox} checked={isArchivedList} customSize={16} />
      <OptionLabel>{label}</OptionLabel>
    </Wrapper>
  );
};

export default ToggleArchivedList;

const Wrapper = styled('div')({
  display: 'flex',
  fontSize: '0.75rem',
  fontWeight: 600,
  height: 32,
  alignItems: 'center',
  justifyContent: 'space-between',
});

const OptionLabel = styled('div')({
  marginLeft: 5,
});
