import styled from '@emotion/styled';
import { bp, layer } from 'entity/createTheme';
import { QuestionViewType } from 'entity/Question';
import { QuestionSetViewType } from 'entity/QuestionSet';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  ContentMeta,
  ContentType,
  ExternalVideoURLMeta,
  UploadImageMeta,
  UploadPDFMeta,
  UploadVideoMeta,
} from 'types/App';
import { ConfirmModal, Drawer, LoadingIndicator } from 'views/components/compound';
import { QuestionList, UploadContent } from '../QuestionForm';

type Props = {
  questionSet: QuestionSetViewType;
  handleChangeQuestion: (index: number, data: Partial<QuestionViewType>) => void;
  addQuestion: () => void;
  removeQuestion: (index: number) => void;
  resetQuestionSetMeta(): void;
  submitUpdateQuestionSet(questionId: string): void;
  sortQuestions({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void;
  contentType: ContentType;
  videoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentType: (type: ContentType) => void;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  disableUpdate: boolean;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  editQuestionSetModalOpen: boolean;
  toggleEditQuestionSetModal: () => void;
  resetExistingContentMeta: () => void;
  editMode: boolean;
};

const EditQuestionModal: FC<Props> = ({
  questionSet,
  handleChangeQuestion,
  addQuestion,
  removeQuestion,
  sortQuestions,
  resetQuestionSetMeta,
  submitUpdateQuestionSet,
  contentType,
  videoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentType,
  handleChangeContentMeta,
  disableUpdate,
  submitting,
  setSubmitting,
  editQuestionSetModalOpen,
  toggleEditQuestionSetModal,
  resetExistingContentMeta,
  editMode,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const questionSetId = useMemo(() => questionSet.id, [questionSet]);
  const [isModal, setIsModal] = useState(false);
  const [errorForms, setErrorForms] = useState<number[]>([]);
  const { firstFrameVideo, file, error, fileThumbnail } = useMemo(() => videoMeta, [videoMeta]);
  const [isLoadingFrame, setIsLoadingFrame] = useState(false);
  const [fileTypeError, setFileTypeError] = useState('');
  const isProductContent = useMemo(() => {
    if (
      questionSet.content.content_type === 'videos' ||
      questionSet.content.content_type === 'images' ||
      questionSet.content.content_type === 'pdfs'
    ) {
      return questionSet.content.is_product_content;
    } else {
      return false;
    }
  }, [questionSet.content.content_type]);

  const modalRef = useRef<HTMLDivElement>(null);

  const errorFormsConst = questionSet.questions
    .map((q, i) => {
      if (!q.body.trim() || q.choices.find(c => !c.content.trim())) return i;
      return null;
    })
    .filter((i): i is number => i !== null);

  useEffect(() => {
    if (!file) {
      setIsLoadingFrame(false);
      return;
    }
    if (error) {
      setIsLoadingFrame(false);
    } else {
      setIsLoadingFrame(true);
    }
  }, [file, error]);

  useEffect(() => {
    if (!error && (firstFrameVideo || fileThumbnail)) {
      setIsLoadingFrame(false);
    }
  }, [firstFrameVideo, fileThumbnail, error]);

  useEffect(() => {
    return () => {
      resetQuestionSetMeta();
    };
  }, []);

  function toggleModal() {
    setIsModal(!isModal);
  }

  function handleCancelEdit() {
    toggleEditQuestionSetModal();
    resetQuestionSetMeta();
    setErrorForms([]);
    setFileTypeError('');
  }

  function updateQuestionSet() {
    toggleEditQuestionSetModal();

    switch (contentType) {
      case 'videos':
        if (videoMeta.firstFrameVideo) {
          submitUpdateQuestionSet(questionSetId.toString());
          setErrorForms([]);
        }
        break;
      case 'links':
      case 'images':
      case 'pdfs':
      case 'none_data':
      case 'existing_contents':
        submitUpdateQuestionSet(questionSetId.toString());
        setErrorForms([]);
        break;
      default:
    }
  }

  function handleConfirmModal() {
    handleCancelEdit();
  }

  const handleSubmitQuestionSet = () => {
    if (errorFormsConst.length) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      setErrorForms(errorFormsConst);
      return;
    }
    setErrorForms([]);
    setSubmitting(true);
    updateQuestionSet();
  };

  const handleFileTypeError = (error: string) => setFileTypeError(error);

  return (
    <Drawer
      isOpen={editQuestionSetModalOpen}
      modalRef={modalRef}
      disabledAction={disableUpdate || submitting}
      onClose={toggleModal}
      onSave={handleSubmitQuestionSet}
      size="lg"
    >
      {isLoadingFrame && (
        <LoadingFrame>
          <LoadingIndicator />
        </LoadingFrame>
      )}

      <UploadContent
        questionSetContent={questionSet.content}
        contentType={contentType}
        videoMeta={videoMeta}
        externalVideoURLMeta={externalVideoURLMeta}
        imageMeta={imageMeta}
        pdfMeta={pdfMeta}
        handleChangeContentType={handleChangeContentType}
        handleChangeContentMeta={handleChangeContentMeta}
        isQuestionModalOpen={editQuestionSetModalOpen}
        isProductContent={!!isProductContent}
        resetExistingContentMeta={resetExistingContentMeta}
        editMode={editMode}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
      />
      <QuestionList
        ref={ref}
        questions={questionSet.questions}
        updateQuestion={handleChangeQuestion}
        addQuestion={addQuestion}
        removeQuestion={removeQuestion}
        errorForms={errorForms}
        errorFormsConst={errorFormsConst}
        clearError={() => setErrorForms([])}
        sortQuestions={sortQuestions}
        isQuestionDrawerOpen={editQuestionSetModalOpen}
      />
      <ConfirmModal
        isModal={isModal}
        isDelete={false}
        toggleModal={toggleModal}
        handleConfirmModal={handleConfirmModal}
      />
    </Drawer>
  );
};

const LoadingFrame = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: `rgba(0, 0, 0, 0.3)`,
  zIndex: layer.bottomAbove2,
  [`@media ${bp.sm}`]: {
    width: '100%',
    left: 0,
  },
});

export default EditQuestionModal;
