import { observable, action, computed } from 'mobx';
import history from 'utility/history';
import { MessageProps, UserInfo, DisplayableMenus } from 'types/App';
import { defaultUser, defaultFlashMessage, defaultDisplayableMenus } from './constants';
import { RootStore } from '../index';
import UserApi from 'services/UserApi';
import OrganizationApi from 'services/OrganizationApi';
import AuthenticationApi from 'services/AuthenticationApi';
import { fromJS } from 'immutable';

class AppStore {
  private readonly userApi: UserApi;
  private readonly organizationApi: OrganizationApi;
  private readonly authenticationApi: AuthenticationApi;
  @observable loading = false;
  @observable mainLoading = false;
  @observable mainLoadingTimeout: any = null;
  @observable public rootStore: RootStore;
  @observable public organizationId = '';
  @observable public flashMessages: MessageProps = { ...defaultFlashMessage };
  @observable public userData: UserInfo = { ...defaultUser };
  @observable public currentLogoUrl: string | null = null;
  @observable public sidebarOpen = false;

  constructor({
    rootStore,
    userApi,
    organizationApi,
    authenticationApi,
  }: {
    rootStore: RootStore;
    userApi: UserApi;
    organizationApi: OrganizationApi;
    authenticationApi: AuthenticationApi;
  }) {
    this.rootStore = rootStore;
    this.userApi = userApi;
    this.organizationApi = organizationApi;
    this.authenticationApi = authenticationApi;
  }

  @computed get isAdmin(): boolean {
    const organization = this.findOrganization();
    return !!organization && !!organization.roles && !!organization.roles.includes('admin');
  }

  @computed get serviceEnabled(): boolean {
    const organization = this.findOrganization();
    return !!organization && organization.service_enabled;
  }

  @computed get sfaIntegrated(): boolean {
    const organization = this.findOrganization();
    return !!organization && organization.sfa_integrated;
  }

  @computed get displayableMenus(): DisplayableMenus {
    const organization = this.findOrganization();
    return Object.assign(
      {
        admin: this.isAdmin,
        not_admin: !this.isAdmin,
      },
      organization && organization.displayable_menus
        ? organization.displayable_menus
        : defaultDisplayableMenus
    );
  }

  @computed get hasLogin(): boolean {
    return Number(this.userData.id) > 0;
  }

  @action.bound
  public clearSession() {
    this.userData = { ...defaultUser };
  }

  @action.bound
  public handleFlashMessage(data: MessageProps) {
    this.flashMessages = data;
  }

  public setMainLoading(val: boolean): void {
    this.mainLoading = val;
  }

  @action.bound
  public async getCurrentUserAndRole(organizationIdFromURL?: string) {
    try {
      this.loading = true;

      const userData = await this.getCurrentUser();
      this.userData = userData;

      const organizationId =
        organizationIdFromURL &&
        !!userData.organizations.find(org => org.id === organizationIdFromURL)
          ? organizationIdFromURL
          : userData.organizations.map(item => item.id)[0];
      await this.setOrganization(organizationId);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async getCurrentUser() {
    const { data } = await this.userApi.getMe();

    return data;
  }

  @action.bound
  public async getOrganizationRole(id?: string) {
    if (!id) return;
    try {
      const { data } = await this.organizationApi.getOrganizationById(id);
      const organization = this.findOrganization();
      if (organization) {
        organization.roles = data.roles;
        organization.displayable_menus = data.displayable_menus;
        organization.service_enabled = data.service_enabled;
        organization.sfa_integrated = data.sfa_integrated;
      }
      this.currentLogoUrl = data.organization_logo;
    } catch (error: any) {
      const content = error?.errors ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  @action.bound
  public async handleLogout(): Promise<void> {
    await this.authenticationApi.logout();
    //Reset organization id when logout
    this.setOrganization('');
    history.go(0);
  }

  @action.bound
  public async handleChangeOrganization(id: string) {
    this.setOrganization(id);
    history.push('/');
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public setSidebarOpen(value: boolean) {
    this.sidebarOpen = value;
  }

  @action.bound
  public async getOrganizationTags(id: string) {
    try {
      const index = this.userData.organizations.findIndex(item => item.id === id);
      if (index >= 0) {
        const { data } = await this.organizationApi.getOrganizationTags(id);
        this.userData = fromJS(this.userData).setIn(['organizations', index, 'tags'], data).toJS();
      }
    } catch (error: any) {
      const content = error?.errors ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  private findOrganization() {
    return this.userData.organizations.find(item => item.id === this.organizationId);
  }

  private async setOrganization(id: string) {
    this.organizationId = id;
    this.mainLoading = true;
    await this.getOrganizationRole(id);
    clearTimeout(this.mainLoadingTimeout);
    this.mainLoadingTimeout = setTimeout(() => {
      this.mainLoading = false;
    }, 1000);
  }
}

export default AppStore;
