import { Content as IContent, ContentUploadStatus, ContentType } from 'types/App';

type ContentMeta = {
  id?: number;
  upload_status: ContentUploadStatus;
};

export interface Video extends ContentMeta {
  content_type: 'videos';
  kaizen_files_url: string | null;
  kaizen_files_id: string;
  kaizen_files_thumbnail_url: string | null;
  kaizen_files_bucket_id: string;
  is_product_content?: boolean;
}

interface Link extends ContentMeta {
  content_type: 'links';
  url: string;
  thumbnail: string;
}

interface Image extends ContentMeta {
  content_type: 'images';
  kaizen_files_url: string;
  is_product_content?: boolean;
}

interface PDF extends ContentMeta {
  content_type: 'pdfs';
  kaizen_files_url: string;
  kaizen_files_thumbnail_url: string | null;
  is_product_content?: boolean;
}

interface NoContent {
  content_type: 'none_data';
}

export type ContentViewType = Video | Link | Image | PDF | NoContent;

export type ExistingContentViewType = Video | Image | PDF;

export class Content {
  private content_type: ContentType;
  private url: string;
  private thumbnail: string;
  private kaizen_files_thumbnail_url: string;
  private upload_status: ContentUploadStatus;
  private kaizen_files_id: string;
  private kaizen_files_url: string;
  private kaizen_files_bucket_id: string;
  private is_product_content?: boolean;
  private id?: number;

  constructor(data: IContent) {
    this.content_type = data?.content_type ?? 'none_data';
    this.url = data?.url ?? '';
    this.thumbnail = data?.thumbnail ?? '';
    this.kaizen_files_url = data?.kaizen_files_url ?? '';
    this.kaizen_files_thumbnail_url = data?.kaizen_files_thumbnail_url ?? '';
    this.upload_status = data?.upload_status;
    this.kaizen_files_thumbnail_url = data?.kaizen_files_thumbnail_url ?? '';
    this.kaizen_files_id = data?.kaizen_files_id ?? '';
    this.kaizen_files_url = data?.kaizen_files_url ?? '';
    this.kaizen_files_bucket_id = data?.kaizen_files_bucket_id ?? '';
    this.is_product_content = !!data?.is_product_content;
    this.id = data?.id || 0;
  }

  static isValid(data: any): data is IContent {
    return true;
  }

  static fromJSON(data: any) {
    if (!this.isValid(data)) throw Error('Invalid type of Content');
    return new Content(data);
  }

  toJSON(): ContentViewType {
    if (this.content_type === 'videos')
      return {
        content_type: 'videos',
        kaizen_files_thumbnail_url: this.kaizen_files_thumbnail_url,
        upload_status: this.upload_status,
        kaizen_files_id: this.kaizen_files_id,
        kaizen_files_url: this.kaizen_files_url,
        kaizen_files_bucket_id: this.kaizen_files_bucket_id,
        is_product_content: this.is_product_content,
        id: this.id,
      };

    if (this.content_type === 'links')
      return {
        content_type: 'links',
        url: this.url,
        thumbnail: this.thumbnail,
        upload_status: this.upload_status,
      };

    if (this.content_type === 'images')
      return {
        content_type: 'images',
        kaizen_files_url: this.kaizen_files_url,
        upload_status: this.upload_status,
        is_product_content: this.is_product_content,
        id: this.id,
      };

    if (this.content_type === 'pdfs')
      return {
        content_type: 'pdfs',
        kaizen_files_thumbnail_url: this.kaizen_files_thumbnail_url,
        kaizen_files_url: this.kaizen_files_url,
        upload_status: this.upload_status,
        is_product_content: this.is_product_content,
        id: this.id,
      };
    return {
      content_type: 'none_data',
    };
  }
}
