import { CreateDefaultLinkData, ProductInfo, ProductItem } from 'types/Product';
import { ListRequestParamsMeta, FilterByTagsParams } from 'types/common';
import HttpClient from 'adapter/HTTPClient';
import { Client as BelfastClient } from '@kaizenplatform/belfast';
import { ContentCreateUpdateAPIBody, Chapter, ContentType } from 'types/App';

class ProductAdminApi {
  private readonly httpClient: HttpClient;
  private readonly belfastClient: BelfastClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
    this.belfastClient = new BelfastClient({
      endpoint: process.env.REACT_APP_KAIZEN_FILES_URL ?? '',
    });
  }

  public getAdminProductList = async ({
    page,
    sort,
    order,
    organization_id,
    tags,
    types,
    q,
  }: Partial<ListRequestParamsMeta & FilterByTagsParams>) => {
    const res = await this.httpClient.request<{
      product_contents: ProductItem[];
      total?: number;
      organization_id: string;
    }>({
      url: 'admin/product_contents',
      method: 'GET',
      params: {
        page,
        order,
        sort,
        per_page: 12,
        organization_id,
        tags,
        types,
        q,
      },
    });
    return res;
  };

  public changeProductStatus = async ({
    id,
    status,
    confirm,
    organization_id,
  }: {
    id: number;
    status: string;
    confirm?: boolean;
    organization_id: string;
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/product_contents/update_status',
      data: {
        ids: [id],
        status,
        confirm,
        organization_id,
      },
    });
  };

  public getProductById = async ({
    id,
    organization_id,
  }: {
    id: number;
    organization_id: string;
  }) => {
    const res = await this.httpClient.request<ProductItem>({
      url: `admin/product_contents/${id}`,
      method: 'GET',
      params: {
        organization_id,
      },
    });

    return res;
  };

  public archiveProduct = async ({
    listId,
    organization_id,
  }: {
    listId: number[];
    organization_id: string;
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/product_contents/update_status',
      data: {
        ids: listId,
        status: 'archived',
        organization_id,
      },
    });
  };

  public addProduct = async ({
    data,
    onUploadProgress,
    organization_id,
  }: {
    data: ContentCreateUpdateAPIBody;
    onUploadProgress: (progressEvent: any) => void;
    organization_id: string;
  }) => {
    type ProductContentPostBody = {
      id: number;
      type: ContentType;
      organization_id: string;
      status: string;
      kaizen_files_id?: string;
      thumbnail_kaizen_files_id?: string;
    };

    const createProductContentRes = await this.httpClient.request<ProductItem>({
      method: 'POST',
      url: 'admin/product_contents',
      data: {
        organization_id,
        title: data.title,
        description: data.description,
        public_description: data.public_description,
      },
      headers: { 'content-type': 'application/json' },
    });

    const productContentBucketID = createProductContentRes.data.kaizen_files_bucket_id;
    const productContentID = createProductContentRes.data.id;
    const productContentPostBody: ProductContentPostBody = {
      id: productContentID,
      type: data.type,
      organization_id,
      status: data.status,
    };

    if (data.file) {
      const belfastFileRes = await this.belfastClient.uploadFile(
        data.file,
        productContentBucketID,
        organization_id,
        {
          onUploadProgress: onUploadProgress,
        },
        true
      );
      productContentPostBody.kaizen_files_id = belfastFileRes.id;
    }

    if (data.thumbnail_file) {
      const belfastThumbRes = await this.belfastClient.uploadFile(
        data.thumbnail_file,
        productContentBucketID,
        organization_id
      );
      productContentPostBody.thumbnail_kaizen_files_id = belfastThumbRes.id;
    }

    const createProductContentPutRes = await this.httpClient.request<ProductItem>({
      method: 'PUT',
      url: `admin/product_contents/${productContentID}`,
      data: { ...productContentPostBody },
    });

    return createProductContentPutRes;
  };

  public updateProduct = async ({
    id,
    data,
    onUploadProgress,
    organization_id,
  }: {
    id: string;
    data: ContentCreateUpdateAPIBody;
    onUploadProgress: (progressEvent: any) => void;
    organization_id: string;
  }) => {
    type UpdateBodyData = {
      organization_id: string;
      type: ContentType;
      chapters: Chapter[];
      kaizen_files_id?: string;
      thumbnail_kaizen_files_id?: string;
    };

    const updateBodyData: UpdateBodyData = {
      organization_id,
      type: data.type,
      chapters: data.chapters,
    };

    if (data.thumbnail_file || data.file) {
      const productContentsGETRes = await this.httpClient.request<{
        kaizen_files_bucket_id: string;
      }>({
        method: 'GET',
        url: `admin/product_contents/${id}?organization_id=${organization_id}`,
      });
      const productContentBucketID = productContentsGETRes.data.kaizen_files_bucket_id;
      if (data.file) {
        const belfastDataResponses = await this.belfastClient.uploadFile(
          data.file,
          productContentBucketID,
          organization_id,
          {
            onUploadProgress: onUploadProgress,
          },
          true
        );
        updateBodyData.kaizen_files_id = belfastDataResponses.id;
      }
      if (data.thumbnail_file) {
        const belfastResponse = await this.belfastClient.uploadFile(
          data.thumbnail_file,
          productContentBucketID,
          organization_id
        );
        updateBodyData.thumbnail_kaizen_files_id = belfastResponse.id;
      }
    }

    const res = await this.httpClient.request<ProductItem>({
      method: 'PUT',
      url: `admin/product_contents/${id}`,
      data: {
        ...updateBodyData,
      },
    });
    return res;
  };

  public updateProductInfo = async ({
    id,
    data,
    organization_id,
  }: {
    id: string;
    data: Partial<ProductInfo>;
    organization_id: string;
  }) => {
    const res = await this.httpClient.request<ProductInfo>({
      method: 'PUT',
      url: `admin/product_contents/${id}`,
      data: {
        ...data,
        organization_id,
      },
    });
    return res;
  };

  public createDefaultLink = async (data: CreateDefaultLinkData, organization_id: string) => {
    const res = await this.httpClient.request({
      method: 'POST',
      url: 'sharing_links/defaults',
      data: {
        ...data,
        organization_id,
      },
    });
    return res;
  };
}

export default ProductAdminApi;
