import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import SelectedContentPreviewer from './SelectedContentPreviewer';
import ExistingContentList from './ExistingContentList';
import { ProductItem } from 'types/Product';
import { BaseListParamsForFilterRequest, FilterByTagsParams, ListMeta } from 'types/common';
import { useTranslation } from 'react-i18next';
import {
  EmptyList,
  SearchArea,
  Pagination,
  LoadingIndicator,
  TagSelects,
} from 'views/components/compound';
import { contentTypesForSelect } from './constants';
import { Tag } from 'types/App';
import { ContentViewType } from 'entity/Content';

type FilterContentParams = {
  page: number;
  types: string[];
  tags: number[];
  q: string;
};

type Props = {
  className?: string;
  getContentList: (params: Partial<BaseListParamsForFilterRequest & FilterByTagsParams>) => void;
  resetContentList: () => void;
  productContents: ProductItem[];
  listMeta: ListMeta;
  needContentFilter?: boolean;
  guideText?: string;
  actionButtons?: React.ReactNode;
  isLoadingList: boolean;
  tags?: Tag[];
  handleSelectContent: (contentId: number, contentType?: string) => void;
  resetSelectedContent: () => void;
  selectedContent: ContentViewType | null;
  selectedContentTitle: string;
  isParentComponentOpen?: boolean;
};

const SelectExistingContent: FC<Props> = ({
  className,
  getContentList,
  resetContentList,
  productContents,
  listMeta,
  needContentFilter,
  guideText,
  actionButtons,
  isLoadingList,
  tags,
  handleSelectContent,
  resetSelectedContent,
  selectedContent,
  selectedContentTitle,
  isParentComponentOpen = true,
}) => {
  const { t } = useTranslation();

  const defaultContentParams = {
    page: 1,
    types: [],
    tags: [],
    q: '',
  };

  const [listParams, setListParams] = useState<FilterContentParams>({ ...defaultContentParams });

  const [selectedTypeIds, setSelectedTypeIds] = useState<number[]>([]);

  useEffect(() => {
    if (isParentComponentOpen) getContentList({ ...listParams });
  }, [listParams, isParentComponentOpen]);

  useEffect(() => {
    if (!isParentComponentOpen) {
      setListParams({ ...defaultContentParams });
      resetContentList();
    }
  }, [isParentComponentOpen]);

  const handleSearchContent = (key: string) => {
    setListParams({ ...listParams, page: 1, q: key });
  };

  const handleSelectTypes = (contentTypes: Tag[]) => {
    const typeIds = contentTypes.map(contentType => contentType.id);
    const types: string[] = typeIds.map(typeId => {
      switch (typeId) {
        case 1:
          return 'videos';
        case 2:
          return 'images';
        case 3:
          return 'pdfs';
        default:
          return '';
      }
    });
    setSelectedTypeIds(typeIds);
    setListParams({ ...listParams, page: 1, types });
  };

  const handleSelectTags = (tagList: Tag[]) => {
    const tagIds = tagList.map(tag => tag.id);
    setListParams({ ...listParams, page: 1, tags: tagIds });
  };

  const handleChangePage = ({ page }: { page?: number }) => {
    setListParams({ ...listParams, page: page || 1 });
  };
  return (
    <Wrapper className={className}>
      {!!selectedContent ? (
        <SelectedContentPreviewer
          selectedContent={selectedContent}
          resetSelectedContent={resetSelectedContent}
          selectedContentTitle={selectedContentTitle}
        />
      ) : (
        <>
          {guideText && <GuideText>{guideText}</GuideText>}

          <SearchArea handleSearch={handleSearchContent} placeholder={t('common.keyword')} />

          <SelectboxWrapper>
            {needContentFilter && (
              <SelectboxInnerWrapper>
                <TagSelects
                  tags={contentTypesForSelect}
                  currentTagIds={selectedTypeIds}
                  onSelectTags={handleSelectTypes}
                />
              </SelectboxInnerWrapper>
            )}
            <TagSelects
              tags={tags || []}
              currentTagIds={listParams.tags}
              onSelectTags={handleSelectTags}
            />
          </SelectboxWrapper>

          {isLoadingList ? (
            <LoadingIndicator />
          ) : productContents && productContents.length > 0 ? (
            <>
              <ExistingContentList
                handleSelectContent={handleSelectContent}
                productContents={productContents}
                actionButtons={actionButtons}
              />
              <Pagination
                handlePaginate={handleChangePage}
                currentPage={listMeta.page}
                total={listMeta.total}
                perPage={12}
              />
            </>
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default SelectExistingContent;

const Wrapper = styled('div')({});

const GuideText = styled('p')({
  fontSize: '0.75rem',
  fontWeight: 400,
  width: '100%',
  textAlign: 'start',
  marginBottom: 12,
});

const SelectboxWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '20px 0px',
});
const SelectboxInnerWrapper = styled('div')({
  marginRight: '10px',
});
