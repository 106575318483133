import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { Button, InputText, SVGIcon } from 'views/components/primitive';
import SearchIcon from 'assets/images/ic_search.svg';
import { palette } from 'entity/createTheme';
import { useKey } from 'react-use';

type Props = {
  handleSearch: (keyword: string) => void;
  placeholder: string;
  className?: string;
};

const SearchArea: FC<Props> = ({ handleSearch, placeholder, className }) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const callback = () => handleSearch(searchKeyword);
  useKey('Enter', callback, undefined, [searchKeyword]);

  return (
    <Wrapper className={className}>
      <SearchKeywordInput
        onChange={(keyword: string) => setSearchKeyword(keyword)}
        value={searchKeyword}
        placeholder={placeholder}
      />
      <SearchButton onClick={() => handleSearch(searchKeyword)} size={'md'} type={'primary'}>
        <SVGIcon src={SearchIcon} uniquifyIDs />
      </SearchButton>
    </Wrapper>
  );
};

export default SearchArea;

const Wrapper = styled('div')({
  display: 'flex',
  width: '100%',
});

const SearchKeywordInput = styled(InputText)({
  marginRight: 12,
  marginBottom: 0,
  height: 40,
  width: '100%',
});

const SearchButton = styled(Button)({
  width: 60,
  svg: {
    margin: 0,
    path: {
      fill: palette.whitePrimary,
    },
  },
});
