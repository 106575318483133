import { RootStore } from 'stores';
import EmbeddingLinkApi from 'services/EmbeddingLinkApi';
import DataPlatformApi from 'services/DataPlatformApi';
import HearingSetApi from 'services/HearingSetApi';
import {
  EmbeddingLinkDetail,
  EmbeddingLink,
  EmbeddingVideo,
  EmbedCodeMeta,
  EmbedInfo,
  InteractiveHearingSetResult,
  ViewRateChart,
} from 'types/EmbeddingLink';
import { defaultEmbeddingLink, defaultListMeta, PLAYLIST_PER_PAGE } from './constants';
import { observable, action, computed } from 'mobx';
import i18n from 'i18n';
import history from 'utility/history';
import { ListMeta } from 'types/common';
import { MessageProps } from 'types/App';
import { routes } from 'utility/constants';
import { searchByTitle, trimSpace } from 'utility/helpers';
import { HearingItem } from 'types/HearingSet';
import { PlaylistItem } from 'types/Playlist';
import PlaylistApi from 'services/Admin/PlaylistApi';

class EmbeddingLinkStore {
  private readonly embeddingLinkApi: EmbeddingLinkApi;
  private readonly dataPlatformApi: DataPlatformApi;
  private readonly hearingSetApi: HearingSetApi;
  private readonly playlistApi: PlaylistApi;

  @observable public loading = false;
  @observable public submitting = false;
  @observable public rootStore: RootStore;
  @observable public embeddingLinkDetail: EmbeddingLinkDetail = { ...defaultEmbeddingLink };
  @observable public embeddingLinkList: EmbeddingLink[] = [];
  @observable public linkListMeta: ListMeta = defaultListMeta;
  @observable public embedCodeList: Record<string, EmbedCodeMeta> = {};
  @observable public videoReachRateChart: ViewRateChart = {};
  @observable public interactiveHearingList: HearingItem[] = [];
  @observable public interactiveHearingListConst: HearingItem[] = [];
  @observable public interactiveHearingSetResult: InteractiveHearingSetResult | null = null;
  @observable public resultLoading = false;
  //For embedding link type playlist
  @observable public playlistList: PlaylistItem[] = [];
  @observable public isLoadingPlaylistList = false;
  @observable public playlistListMeta: ListMeta = { ...defaultListMeta };

  constructor({
    rootStore,
    embeddingLinkApi,
    dataPlatformApi,
    hearingSetApi,
    playlistApi,
  }: {
    rootStore: RootStore;
    embeddingLinkApi: EmbeddingLinkApi;
    dataPlatformApi: DataPlatformApi;
    hearingSetApi: HearingSetApi;
    playlistApi: PlaylistApi;
  }) {
    this.rootStore = rootStore;
    this.embeddingLinkApi = embeddingLinkApi;
    this.dataPlatformApi = dataPlatformApi;
    this.hearingSetApi = hearingSetApi;
    this.playlistApi = playlistApi;
  }

  @computed get showLoadMorePlaylistButton() {
    return (
      !!this.playlistListMeta.total &&
      this.playlistListMeta.total > this.playlistListMeta.page * PLAYLIST_PER_PAGE
    );
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getEmbeddingLinkList(page = 1) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.loading = true;
      const { data } = await this.embeddingLinkApi.getEmbeddingLinks({
        page,
        order: 'updated_at',
        sort: 'desc',
        per_page: 20,
        organization_id,
      });
      this.embeddingLinkList = data.embedding_links;
      this.linkListMeta = { ...this.linkListMeta, total: data.total, page };
    } catch (error: any) {
      const content = error?.message ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public resetEmbeddingLinkList() {
    this.embeddingLinkList = [];
    this.linkListMeta = { ...defaultListMeta };
  }

  @action.bound
  public async getEmbedCodeByLinkId(id: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.embeddingLinkApi.getEmbeddingLinkById(id, organization_id);
      this.embedCodeList = {
        ...this.embedCodeList,
        [id]: {
          feedjs_url: data.feedjs_url,
          hash_token: data.hash_token,
          content_type: data.content_type,
        },
      };
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.linkNotFound');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    }
  }

  @action.bound
  public async getEmbeddingLinkDetail(id: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.loading = true;
      const { data } = await this.embeddingLinkApi.getEmbeddingLinkById(id, organization_id);
      this.embeddingLinkDetail = data;
      this.loading = false;
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.linkNotFound');
      this.putFlashMessages({
        content,
        status: 'error',
      });
      history.push(routes.embeddingLink);
    }
  }

  @action.bound
  public async getInteractiveHearingSetResult(id: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.resultLoading = true;
      const { data } = await this.embeddingLinkApi.getInteractiveHearingSetResult(
        id,
        organization_id
      );
      this.interactiveHearingSetResult = data;
    } catch (error: any) {
      const content = error?.message || 'error';
      this.putFlashMessages({
        content,
        status: 'error',
      });
    } finally {
      this.resultLoading = false;
    }
  }

  @action.bound
  public resetEmbeddingLink() {
    this.embeddingLinkDetail = { ...defaultEmbeddingLink };
    this.interactiveHearingSetResult = null;
    this.videoReachRateChart = {};
  }

  @action.bound
  public async saveInfo(id: number, data: { title: string; description: string }) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      const payload = {
        title: trimSpace(data.title),
        description: trimSpace(data.description),
        ...(this.embeddingLinkDetail.content_type === 'playlist' && {
          content_playlist_id: this.embeddingLinkDetail.content_playlist?.id,
        }),
      };
      const res = await this.embeddingLinkApi.updateEmbeddingLink({
        id,
        data: payload,
        organization_id,
      });
      this.embeddingLinkDetail = res.data;
      this.putFlashMessages({
        content: i18n.t('embeddingLink.messages.updateSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.updateFail');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public async savePlaybackSetting(id: number, data: Partial<EmbeddingVideo>) {
    const {
      show_playback_control,
      show_volume_control,
      end_screen_type,
      show_center_playback_button,
      fullscreen,
      muted,
      autoplay,
    } = data;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      const res = await this.embeddingLinkApi.updateEmbeddingLink({
        id,
        data: {
          show_volume_control,
          show_playback_control,
          end_screen_type,
          show_center_playback_button,
          fullscreen,
          muted,
          autoplay,
        },
        organization_id,
      });
      this.embeddingLinkDetail = res.data;
      this.putFlashMessages({
        content: i18n.t('embeddingLink.messages.updateSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.updateFail');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public async createNewEmbeddingLink(embedInfo: EmbedInfo) {
    const { content_type, title, description, hearing_set_id, content_playlist_id } = embedInfo;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      if (content_type === 'embedding_interactive_hearing_set' && !hearing_set_id) return;
      const data = {
        content_type,
        title: trimSpace(title),
        description: trimSpace(description || ''),
        ...(content_type === 'embedding_interactive_hearing_set' && {
          hearing_set_id,
        }),
        ...(content_type === 'playlist' && {
          content_playlist_id,
        }),
      };
      this.loading = true;
      const { data: createdLink } = await this.embeddingLinkApi.createEmbeddingLink(
        data,
        organization_id
      );
      const newLinkId = createdLink.id;
      this.putFlashMessages({
        content: i18n.t('embeddingLink.messages.createSuccess'),
        status: 'success',
      });
      history.push(`${routes.embeddingLink}/${newLinkId}`);
    } catch (error: any) {
      this.putFlashMessages({
        content: i18n.t('embeddingLink.messages.createFail'),
        status: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async getVideoReachRateChart(
    serviceResourceIds: string[],
    placement: string,
    videoId: string
  ) {
    this.resultLoading = true;
    try {
      const { data } = await this.dataPlatformApi.getChart(serviceResourceIds, placement, videoId);
      this.videoReachRateChart = data.data;
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.getViewRateError');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    } finally {
      this.resultLoading = false;
    }
  }

  @action.bound
  public async updateEmbeddingVideo(id: number, videoId: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      const payload = {
        content_id: videoId,
      };
      const res = await this.embeddingLinkApi.updateEmbeddingLink({
        id,
        data: payload,
        organization_id,
      });
      this.embeddingLinkDetail = res.data;
      this.putFlashMessages({
        content: i18n.t('embeddingLink.messages.updateSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.updateFail');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public async getInteractiveHearings() {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const result = await this.hearingSetApi.getHearingSets(organization_id, {
        is_interactive: 1,
      });
      this.interactiveHearingListConst = result.data.hearing_sets;
      this.interactiveHearingList = result.data.hearing_sets;
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.getInteractiveHearingError');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    }
  }

  @action.bound
  public handleSearchInteractiveHearings(keyword: string) {
    this.interactiveHearingList = searchByTitle<HearingItem>(
      this.interactiveHearingListConst,
      keyword
    );
  }

  @action.bound
  public resetInteractiveHearingList = () => {
    this.interactiveHearingListConst = [];
    this.interactiveHearingList = [];
  };

  //For embedding link type playlist:
  @action.bound
  public async getPlaylistList({ page = 1, key }: { page: number; key?: string }) {
    this.isLoadingPlaylistList = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.playlistApi.getPublishedPlaylists({
        page,
        order: 'updated_at',
        sort: 'desc',
        per_page: PLAYLIST_PER_PAGE,
        key,
        organization_id,
      });
      this.playlistList =
        page === 1 ? data.content_playlists : this.playlistList.concat(data.content_playlists);
      this.playlistListMeta = {
        ...this.playlistListMeta,
        page,
        total: data.total,
      };
    } catch (error) {
      const content = error?.message || i18n.t('embeddingLink.messages.getPlaylistError');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    } finally {
      this.isLoadingPlaylistList = false;
    }
  }

  @action.bound
  public resetPlaylistList() {
    this.playlistList = [];
    this.playlistListMeta = { ...defaultListMeta };
  }

  @action.bound
  public async updatePlaylist(
    id: number,
    { content_playlist_id }: { content_playlist_id: number }
  ) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      const payload = {
        content_playlist_id,
      };
      const res = await this.embeddingLinkApi.updateEmbeddingLink({
        id,
        data: payload,
        organization_id,
      });
      this.embeddingLinkDetail = res.data;
      this.putFlashMessages({
        content: i18n.t('embeddingLink.messages.updateSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      const content = error?.message || i18n.t('embeddingLink.messages.updateFail');
      this.putFlashMessages({
        content,
        status: 'error',
      });
    } finally {
      this.submitting = false;
    }
  }
}

export default EmbeddingLinkStore;
