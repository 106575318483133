import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useHoverDirty } from 'react-use';
import Plyr from '@kaizenplatform/kaizen-video-player/dist/plyr';
import { formatDuration } from 'utility/helpers';
import { palette } from 'entity/createTheme';
import { KaizenVideoPlayer } from 'views/components/compound';

type Props = {
  videoDuration: number;
  videoUrl: string;
  videoId: string;
  uploadStatus: string;
  thumbnailUrl: string;
};

const PreviewOnHover: FC<Props> = ({
  thumbnailUrl,
  uploadStatus,
  videoDuration,
  videoUrl,
  videoId,
}) => {
  const [shouldRenderVideo, setShouldRenderVideo] = useState(false);
  const [player, setPlayer] = useState<Plyr | null>(null);
  const [controlEl, setControlEl] = useState<HTMLDivElement | null>(null);
  const [ready, setReady] = useState(false);
  const videoPlayerRef = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(videoPlayerRef, true);

  const duration = videoDuration
    ? formatDuration(videoDuration, videoDuration > 60 * 60 * 1000)
    : '0:00';

  const toggleControls = (showControls: boolean, controlElement: HTMLDivElement | null) => {
    if (controlElement) {
      if (showControls) {
        controlElement.style.display = 'flex';
      } else {
        controlElement.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    isHovering && setShouldRenderVideo(true);
  }, [isHovering]);

  useEffect(() => {
    if (!player) return;
    if (isHovering && !player.playing) {
      player.play();
    }
    if (!isHovering && player.playing && !!player.currentTime) {
      player.pause();
    }
    toggleControls(isHovering, controlEl);
  }, [isHovering, player, player?.playing, player?.currentTime]);

  return (
    <Wrapper ref={videoPlayerRef}>
      <ThumbnailWrapper isDisplay={!shouldRenderVideo || uploadStatus !== 'uploaded'}>
        <img src={thumbnailUrl} alt="prod-img" />
        <VideoDuration>{duration}</VideoDuration>
      </ThumbnailWrapper>
      {shouldRenderVideo && uploadStatus === 'uploaded' && (
        <VideoWrapper visibility={ready ? 'visible' : 'hidden'}>
          <KaizenVideoPlayer
            key={videoId}
            videoId={videoId}
            manifestUrl={videoUrl}
            onReady={() => setReady(true)}
            poster={thumbnailUrl}
            scriptKey={''}
            muted
            autoplay={false}
            listeners={[
              [
                'ready',
                event => {
                  setPlayer(event.detail.plyr);
                  const rootEl = event.detail.player.root;
                  const plyrControls = rootEl.querySelector('.plyr__controls');
                  setControlEl(plyrControls);
                },
              ],
            ]}
            plyrOptions={{
              controls: ['play', 'progress', 'current-time'],
              resetOnEnd: true,
              clickToPlay: true,
            }}
          />
        </VideoWrapper>
      )}
    </Wrapper>
  );
};

export default PreviewOnHover;

const Wrapper = styled('div')({
  position: 'relative',
  height: '100%',
  width: '100%',
});

const VideoWrapper = styled('div')<{
  visibility: 'visible' | 'hidden';
}>(props => ({
  height: '100%',
  width: '100%',
  visibility: props.visibility,
}));

const ThumbnailWrapper = styled('div')<{
  isDisplay: boolean;
}>(props => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  height: '100%',
  width: '100%',
  '& > *': {
    opacity: props.isDisplay ? 1 : 0,
    transition: '0.8s linear',
  },
}));

const VideoDuration = styled('span')({
  position: 'absolute',
  bottom: 4,
  right: 4,
  backgroundColor: palette.blackPrimary,
  color: palette.whitePrimary,
  borderRadius: 2,
  fontSize: '0.75rem',
  padding: 4,
  fontWeight: 500,
});
