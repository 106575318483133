import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { bp, layer } from 'entity/createTheme';
import { QuestionViewType } from 'entity/Question';
import { QuestionSetViewType } from 'entity/QuestionSet';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  ContentMeta,
  ContentType,
  ExternalVideoURLMeta,
  UploadImageMeta,
  UploadPDFMeta,
  UploadVideoMeta,
} from 'types/App';
import {
  ConfirmModal as ConfirmCancelModal,
  LoadingIndicator,
  Drawer,
} from 'views/components/compound';
import { QuestionList, UploadContent } from 'views/components/compound/QuestionForm';

type Props = {
  questionSet: QuestionSetViewType;
  updateQuestion: (index: number, data: Partial<QuestionViewType>) => void;
  addQuestion: () => void;
  removeQuestion: (index: number) => void;
  sortQuestions({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void;
  resetQuestionSetMeta(): void;
  submitAddQuestionSet(): void;
  contentType: ContentType;
  videoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentType: (type: ContentType) => void;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  disableSubmit: boolean;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  createQuestionSetModalOpen: boolean;
  toggleCreateQuestionSetModal: () => void;
  resetExistingContentMeta: () => void;
  isProductContent?: boolean;
};

const AddQuestionSetModal: FC<Props> = ({
  questionSet,
  addQuestion,
  updateQuestion,
  removeQuestion,
  sortQuestions,
  resetQuestionSetMeta,
  submitAddQuestionSet,
  contentType,
  videoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentType,
  handleChangeContentMeta,
  disableSubmit,
  submitting,
  setSubmitting,
  createQuestionSetModalOpen,
  toggleCreateQuestionSetModal,
  resetExistingContentMeta,
  isProductContent,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { firstFrameVideo, file, error, fileThumbnail } = useMemo(() => videoMeta, [videoMeta]);
  const [isLoadingFrame, setIsLoadingFrame] = useState(false);
  const [fileTypeError, setFileTypeError] = useState('');

  const isError = questionSet.questions.find(
    q => !q.body.trim() || q.choices.find(c => !c.content.trim())
  );
  const modalRef = useRef<HTMLDivElement>(null);

  const [errorForms, setErrorForms] = useState<number[]>([]);
  const errorFormsConst = questionSet.questions
    .map((q, i) => {
      if (!q.body.trim() || q.choices.find(c => !c.content.trim())) return i;
      return null;
    })
    .filter((i): i is number => i !== null);

  useEffect(() => {
    if (!file) {
      setIsLoadingFrame(false);
      return;
    }
    if (error) {
      setIsLoadingFrame(false);
    } else {
      setIsLoadingFrame(true);
    }
  }, [file, error]);

  useEffect(() => {
    if (firstFrameVideo || fileThumbnail) {
      setIsLoadingFrame(false);
    }
  }, [firstFrameVideo, fileThumbnail]);

  useEffect(() => {
    return () => {
      resetQuestionSetMeta();
    };
  }, []);

  function toggleModal() {
    setIsCancelModalOpen(!isCancelModalOpen);
  }

  function handleCancelUpload() {
    resetQuestionSetMeta();
    toggleCreateQuestionSetModal();
    setErrorForms([]);
  }

  function handleSetError(hasError: boolean) {
    setErrorForms(errorFormsConst);
  }

  function handleAddQuestionSet() {
    toggleCreateQuestionSetModal();
    submitAddQuestionSet();
  }

  function handleConfirmModal() {
    setErrorForms([]);
    handleCancelUpload();
    setFileTypeError('');
  }

  const submitCreateQuestionSet = () => {
    if (isError) {
      handleSetError(true);
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    handleSetError(false);
    setSubmitting(true);
    handleAddQuestionSet();
  };

  const handleFileTypeError = (error: string) => setFileTypeError(error);

  return (
    <Drawer
      isOpen={createQuestionSetModalOpen}
      modalRef={modalRef}
      disabledAction={disableSubmit || submitting}
      onSave={submitCreateQuestionSet}
      size="lg"
      onClose={toggleModal}
      saveButtonLabel={t('common.createNew')}
    >
      {isLoadingFrame && (
        <LoadingFrame>
          <LoadingIndicator />
        </LoadingFrame>
      )}
      <UploadContent
        questionSetContent={questionSet.content}
        contentType={contentType}
        videoMeta={videoMeta}
        externalVideoURLMeta={externalVideoURLMeta}
        imageMeta={imageMeta}
        pdfMeta={pdfMeta}
        handleChangeContentType={handleChangeContentType}
        handleChangeContentMeta={handleChangeContentMeta}
        isQuestionModalOpen={createQuestionSetModalOpen}
        resetExistingContentMeta={resetExistingContentMeta}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
        isProductContent={!!isProductContent}
      />
      <QuestionList
        ref={ref}
        sortQuestions={sortQuestions}
        questions={questionSet.questions}
        updateQuestion={updateQuestion}
        addQuestion={addQuestion}
        errorForms={errorForms}
        clearError={() => setErrorForms([])}
        errorFormsConst={errorFormsConst}
        removeQuestion={removeQuestion}
        isQuestionDrawerOpen={createQuestionSetModalOpen}
      />
      <ConfirmCancelModal
        isModal={isCancelModalOpen}
        toggleModal={toggleModal}
        handleConfirmModal={handleConfirmModal}
      />
    </Drawer>
  );
};

const LoadingFrame = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: `rgba(0, 0, 0, 0.3)`,
  zIndex: layer.bottomAbove2,
  [`@media ${bp.sm}`]: {
    width: '100%',
    left: 0,
  },
});

export default AddQuestionSetModal;
