import i18n from 'i18n';
export const contentTypesForSelect = [
  {
    id: 1,
    name: i18n.t('creativeSearch.contentType.video'),
    category_name: i18n.t('creativeSearch.fileType'),
  },
  {
    id: 2,
    name: i18n.t('creativeSearch.contentType.image'),
    category_name: i18n.t('creativeSearch.fileType'),
  },
  {
    id: 3,
    name: i18n.t('creativeSearch.contentType.pdf'),
    category_name: i18n.t('creativeSearch.fileType'),
  },
];
