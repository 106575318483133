import NoThumbnail from 'assets/images/no-thumbnail.jpg';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentMeta,
  ContentType,
  ContentUploadStatus,
  ExternalVideoURLMeta,
  UploadImageMeta,
  UploadPDFMeta,
  UploadVideoMeta,
} from 'types/App';
import {
  acceptImage,
  acceptImageMimeTypes,
  acceptPDF,
  acceptPDFMimeTypes,
  acceptVideo,
  maxSizeImage,
  maxSizeImageClient,
  maxSizePdf,
  maxSizePdfClient,
  videoAcceptShow,
} from 'utility/constants';
import { extractFileExtentionFromMimeType } from 'utility/helpers';
import PDF2Image from 'utility/Pdf2Image';
import ExternalVideoURLInput from './ExternalVideoURLInput';
import NoContent from './NoContent';
import { QuestionFormUploadFile as UploadFile } from './UploadFile';
import UploadImage from './UploadImage';
import UploadPDF from './UploadPDF';
import UploadVideo from './UploadVideo';
import { ContentViewType, ExistingContentViewType } from 'entity/Content';
import { isAcceptableFile } from 'utility/helpers';
import SelectExistingContent from '../SelectExistingContent';
import { useStore } from 'hooks';
import { useCurrentOrganization } from 'organisms/useCurrentOrganization';
import { observer } from 'mobx-react';

const EXISTING_CONTENT_TYPES = ['videos', 'images', 'pdfs'];

type Props = {
  contentType: ContentType;
  videoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  uploadStatus?: string;
  handleChangeContentType: (type: ContentType) => void;
  originalExistingContentId?: number;
  resetExistingContentMeta: () => void;
  setIsCreateFromExisting: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateFromExisting: boolean;
  originalContent: ContentViewType;
  editMode: boolean;
  fileTypeError: string;
  handleFileTypeError: (fileTypeError: string) => void;
  isProductContent: boolean;
  isQuestionModalOpen: boolean;
};

const ContentUploader: FC<Props> = ({
  contentType,
  videoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  resetExistingContentMeta,
  handleChangeContentMeta,
  uploadStatus,
  handleChangeContentType,
  setIsCreateFromExisting,
  isCreateFromExisting,
  originalContent,
  editMode,
  fileTypeError,
  handleFileTypeError,
  isProductContent,
  isQuestionModalOpen,
}) => {
  const { t } = useTranslation();
  const {
    salesProductListStore: {
      getProductContentList,
      isLoading,
      resetProductList,
      productListMeta,
      productList,
    },
  } = useStore();

  const getContentList = ({
    tags,
    q,
    types,
    page,
  }: {
    tags?: number[];
    q?: string;
    types?: string[];
    page?: number;
  }) => {
    getProductContentList({
      page,
      tags,
      q,
      types: types && types.length > 0 ? types : ['videos', 'pdfs', 'images'],
    });
  };

  const currentOrganization = useCurrentOrganization();

  const tags = currentOrganization?.tags;

  const [selectedContent, setSelectedContent] = useState<ExistingContentViewType | null>(null);
  const [shouldShowDropzone, setShouldShowDropzone] = useState<boolean>(true);

  useEffect(() => {
    const typeUploadedImage = contentType === 'images' && !!imageMeta.src;
    const typeUploadedPdf = contentType === 'pdfs' && !!pdfMeta.thumbnailSrc;
    const typeUploadedVideo = contentType === 'videos' && !!videoMeta.firstFrameVideo;
    setShouldShowDropzone(!typeUploadedImage && !typeUploadedPdf && !typeUploadedVideo);
  }, [isQuestionModalOpen]);

  useEffect(() => {
    if (
      isProductContent &&
      originalContent.content_type !== 'none_data' &&
      originalContent.content_type !== 'links'
    ) {
      setSelectedContent(originalContent);
      setIsCreateFromExisting(true);
    }
  }, [originalContent, isProductContent]);

  useEffect(() => {
    (!isQuestionModalOpen || contentType !== 'existing_contents') && resetSelectedContent();
  }, [isQuestionModalOpen, contentType]);

  const resetContentList = () => {
    resetProductList();
    if (
      isProductContent &&
      originalContent.content_type !== 'none_data' &&
      originalContent.content_type !== 'links'
    ) {
      setSelectedContent(originalContent);
      setIsCreateFromExisting(true);
    }
  };

  const handleSelectContent = (contentId: number, contentType?: string) => {
    handleChangeContentMeta({
      type: 'existing_contents',
      existing_content_type: contentType || '',
      contentId,
    });
    setIsCreateFromExisting(true);
    const targetContent = productList.filter(content => content.content_id === contentId)[0];
    if (!EXISTING_CONTENT_TYPES.includes(targetContent.content_type)) return;
    setSelectedContent({
      kaizen_files_bucket_id: targetContent.kaizen_files_bucket_id || '',
      kaizen_files_id:
        targetContent.content_type === 'videos' ? targetContent.kaizen_files_id || '' : '',
      kaizen_files_thumbnail_url:
        targetContent.content_type !== 'images'
          ? targetContent.kaizen_files_thumbnail_url || ''
          : '',
      kaizen_files_url: targetContent.kaizen_files_url || '',
      upload_status: targetContent.upload_status as ContentUploadStatus,
      content_type: targetContent.content_type,
    });
  };

  const resetSelectedContent = () => {
    resetExistingContentMeta();
    setSelectedContent(null);
  };

  const onInputImage = (newFile: any, meta: UploadImageMeta) => {
    const extension = extractFileExtentionFromMimeType(newFile.type);

    if (acceptImageMimeTypes.includes(extension)) {
      const exceedMaxSize = newFile.size >= maxSizeImage;
      if (exceedMaxSize) {
        handleChangeContentMeta({
          ...meta,
          file: '',
          filename: '',
          src: NoThumbnail,
          error: t('admin.common.exceedMaxSize', { sizeLimit: maxSizeImageClient }),
        });
      } else {
        handleChangeContentMeta({
          ...meta,
          file: newFile,
          filename: newFile.name,
          src: URL.createObjectURL(newFile),
          error: '',
        });
      }
    }
  };

  const onInputPdf = async (newFile: any, meta: UploadPDFMeta) => {
    const extension = extractFileExtentionFromMimeType(newFile.type);

    if (acceptPDFMimeTypes.includes(extension)) {
      const url = URL.createObjectURL(newFile);
      const pdf2image = await PDF2Image.open(url);

      const thumbnailSrc = await pdf2image.getImageDataUrl(1, {
        width: 400,
        height: 400,
      });

      const exceedMaxSize = newFile.size >= maxSizePdf;
      if (exceedMaxSize) {
        handleChangeContentMeta({
          ...meta,
          file: '',
          filename: '',
          error: t('admin.common.exceedMaxSize', { sizeLimit: maxSizePdfClient }),
          thumbnailSrc: NoThumbnail,
        });
      } else {
        handleChangeContentMeta({
          ...meta,
          file: newFile,
          filename: newFile.name,
          error: '',
          ...(thumbnailSrc && { thumbnailSrc }),
        });
      }
    }
  };

  const onInputVideo = (newFile: any, meta: UploadVideoMeta) => {
    const extension = extractFileExtentionFromMimeType(newFile.type);

    if (videoAcceptShow.includes(extension)) {
      handleChangeContentMeta({
        ...meta,
        file: newFile,
        videoSrcConvert: URL.createObjectURL(newFile),
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        error: '',
      });
    } else {
      handleChangeContentMeta({
        ...meta,
        file: newFile,
        videoSrcConvert: null,
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        error: t('admin.common.notLoadThumbnail', { filename: newFile.name }),
        fileThumbnail: '',
        firstFrameVideo: NoThumbnail,
      });
    }
  };

  const handleChangeUploadFile = (newFile: any) => {
    if (!newFile) return;
    const extension = newFile.name.split('.').pop().toLowerCase();
    const extensionWithDot = `.${extension}`;
    setShouldShowDropzone(false);

    if (isAcceptableFile(extensionWithDot, acceptVideo)) {
      handleChangeContentType('videos');
      onInputVideo(newFile, videoMeta);
    } else if (isAcceptableFile(extensionWithDot, acceptImage)) {
      handleChangeContentType('images');
      onInputImage(newFile, imageMeta);
    } else if (isAcceptableFile(extensionWithDot, acceptPDF)) {
      handleChangeContentType('pdfs');
      onInputPdf(newFile, pdfMeta);
    }
  };

  //For contentType 'existing_contents'
  if (isCreateFromExisting || contentType === 'existing_contents') {
    return (
      <SelectExistingContent
        getContentList={getContentList}
        resetContentList={resetContentList}
        productContents={productList}
        listMeta={productListMeta}
        guideText={t('embeddingLink.selectEmbedVideo.guide')}
        isLoadingList={isLoading}
        tags={tags}
        handleSelectContent={handleSelectContent}
        selectedContent={selectedContent}
        selectedContentTitle={''}
        resetSelectedContent={resetSelectedContent}
        needContentFilter
        isParentComponentOpen={isQuestionModalOpen}
      />
    );
  }

  //For contentType 'videos_images_pdfs;
  if (contentType === 'videos_images_pdfs') {
    if (pdfMeta.thumbnailSrc)
      return (
        <UploadPDF
          meta={pdfMeta}
          editable
          handleChangeUploadFile={handleChangeUploadFile}
          notReady={pdfMeta.thumbnailSrc === NoThumbnail}
          editMode={editMode}
          originalContent={originalContent}
        />
      );
    if (imageMeta.src) {
      return (
        <UploadImage
          meta={imageMeta}
          editable
          handleChangeUploadFile={handleChangeUploadFile}
          notReady={imageMeta.src === NoThumbnail}
        />
      );
    }
    if (videoMeta.firstFrameVideo) {
      return (
        <UploadVideo
          handleChangeMeta={handleChangeContentMeta}
          meta={videoMeta}
          status={uploadStatus || ''}
          handleChangeUploadFile={handleChangeUploadFile}
          originalContent={originalContent}
          editMode={editMode}
        />
      );
    }
    return (
      <UploadFile
        handleChangeUploadFile={handleChangeUploadFile}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
      />
    );
  }

  //For contentType 'images':
  if (contentType === 'images') {
    return !shouldShowDropzone ? (
      <UploadImage
        meta={imageMeta}
        editable
        handleChangeUploadFile={handleChangeUploadFile}
        notReady={imageMeta.src === NoThumbnail}
      />
    ) : (
      <UploadFile
        handleChangeUploadFile={handleChangeUploadFile}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
      />
    );
  }

  //For contentType 'pdfs':
  if (contentType === 'pdfs') {
    return !shouldShowDropzone ? (
      <UploadPDF
        meta={pdfMeta}
        editable
        handleChangeUploadFile={handleChangeUploadFile}
        notReady={pdfMeta.thumbnailSrc === NoThumbnail}
        editMode={editMode}
        originalContent={originalContent}
      />
    ) : (
      <UploadFile
        handleChangeUploadFile={handleChangeUploadFile}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
      />
    );
  }

  //For contentType 'videos':
  if (contentType === 'videos') {
    return !shouldShowDropzone ? (
      <UploadVideo
        handleChangeMeta={handleChangeContentMeta}
        meta={videoMeta}
        status={uploadStatus || ''}
        handleChangeUploadFile={handleChangeUploadFile}
        originalContent={originalContent}
        editMode={editMode}
      />
    ) : (
      <UploadFile
        handleChangeUploadFile={handleChangeUploadFile}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
      />
    );
  }

  if (contentType === 'links')
    return (
      <ExternalVideoURLInput
        meta={externalVideoURLMeta}
        handleChangeContentMeta={handleChangeContentMeta}
      />
    );

  if (contentType === 'none_data') return <NoContent />;

  return null;
};

export default observer(ContentUploader);
