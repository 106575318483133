import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button, SVGIcon } from 'views/components/primitive';
import LeftIcon from 'assets/images/ic_left.svg';
import ImgWaitToUpload from 'assets/images/upload.gif';
import { useTranslation } from 'react-i18next';
import { bp, palette } from 'entity/createTheme';
import HearingContentPlayer from '../HearingContentPlayer';
import { ContentViewType } from 'entity/Content';

type Props = {
  selectedContent: ContentViewType;
  resetSelectedContent: () => void;
  selectedContentTitle: string;
};

const SelectedContentPreviewer: FC<Props> = ({
  selectedContent,
  resetSelectedContent,
  selectedContentTitle,
}) => {
  const { t } = useTranslation();
  if (!selectedContent || selectedContent.content_type === 'none_data') return null;
  return (
    <Wrapper>
      <PreviewArea>
        <div>
          <PreviewWrapper>
            <MainFrame
              className={
                selectedContent.upload_status === 'not_uploaded' ? 'not-uploaded' : undefined
              }
            >
              {selectedContent.content_type === 'videos' && !selectedContent.kaizen_files_url ? (
                <img src={ImgWaitToUpload} alt="video_thumbnail" />
              ) : (
                <HearingContentPlayer content={selectedContent} />
              )}
            </MainFrame>
          </PreviewWrapper>
        </div>
      </PreviewArea>

      <InfoWrapper>
        <Title>{selectedContentTitle}</Title>
      </InfoWrapper>

      <ActionButtons>
        <Button
          type="default"
          size="sm"
          label={
            <>
              <SVGIcon src={LeftIcon} />
              <ButtonLabelText>{t('creativeSearch.selectAnotherContent')}</ButtonLabelText>
            </>
          }
          onClick={() => {
            resetSelectedContent();
          }}
        />
      </ActionButtons>
    </Wrapper>
  );
};

export default SelectedContentPreviewer;

const Wrapper = styled('div')({
  width: '100%',
});

const PreviewArea = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '& > div': {
    width: '100%',
    maxWidth: 680,
    maxHeight: 380,
  },
});

const PreviewWrapper = styled('div')({
  width: '100%',
  position: 'relative',
  paddingTop: '56.25%', //Make width:height ratio of Root equal to 16:9
  '& .kaizen-video-player': { aspectRatio: 'inherit' },
});

const MainFrame = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0,
  background: palette.blackPrimary,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  '&.not-uploaded': {
    background: palette.whitePrimary,
  },
  '& > #image-viewer': {
    overflow: 'hidden',
  },
  '& > #video-player-wrapper': {
    transform: 'translateZ(0)',
    borderRadius: 4,
    overflow: 'hidden',
  },
});

const InfoWrapper = styled('div')({});

const Title = styled('h3')({
  fontSize: '1.25rem',
  margin: '20px 0 10px 0',
  [`@media ${bp.md}`]: {
    margin: '16px 0 8px 0',
  },
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
});

const ActionButtons = styled('div')({});

const ButtonLabelText = styled('span')({
  display: 'inline-block',
  marginLeft: '10px',
  color: palette.deepBluePrimary,
});
