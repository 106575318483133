import { observable, action } from 'mobx';
import i18n from 'i18n';
import { MessageProps } from 'types/App';
import { ListMeta } from 'types/common';
import { RootStore } from '../../index';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import { HearingItem } from 'types/HearingSet';
import { routes } from 'utility/constants';
import history from 'utility/history';
import { defaultListMeta } from '../constants';
import { trimSpace } from 'utility/helpers';
import ArchiveAdminApi from 'services/Admin/ArchiveAdminApi';

class HearingListStore {
  private readonly hearingAdminApi: HearingAdminApi;
  private readonly archiveAdminApi: ArchiveAdminApi;
  @observable rootStore: RootStore;
  @observable public isLoading = false;
  @observable public hearingListMeta: ListMeta = { ...defaultListMeta };
  @observable public hearingList: HearingItem[] = [];
  @observable public errors = {};
  @observable public isSelectMode = false;
  @observable public selectedHearings: number[] = [];
  @observable public targetHearing: { id: number; status: string } = { id: 0, status: '' };

  constructor({
    rootStore,
    hearingAdminApi,
    archiveAdminApi,
  }: {
    rootStore: RootStore;
    hearingAdminApi: HearingAdminApi;
    archiveAdminApi: ArchiveAdminApi;
  }) {
    this.rootStore = rootStore;
    this.hearingAdminApi = hearingAdminApi;
    this.archiveAdminApi = archiveAdminApi;
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public changeTargetHearing = (id: number, status: string) => {
    this.targetHearing = { ...this.targetHearing, id, status };
  };

  @action.bound
  public async getHearingList(page = 1, sort = 'latest', order = 'updated_at') {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    this.isLoading = true;
    this.resetHearingList();
    try {
      const { data } = await this.hearingAdminApi.getAdminHearingList({
        page,
        sort,
        order,
        organization_id,
      });
      this.hearingListMeta = { page, sort, order, total: data.total };
      this.hearingList = data.hearing_sets;
    } catch (error: any) {
      this.errors = error;
      this.resetHearingList();
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetHearingList() {
    this.hearingList = [];
    this.hearingListMeta = { ...defaultListMeta };
    this.resetSelectMeta();
  }

  @action.bound
  public toggleSelectMode(): void {
    if (this.isSelectMode) {
      this.selectedHearings = [];
    }
    this.isSelectMode = !this.isSelectMode;
  }

  @action.bound
  public resetSelectMeta() {
    this.isSelectMode = false;
    this.selectedHearings = [];
  }

  @action.bound
  public toggleHearingSelectState(id: number): void {
    if (this.selectedHearings.includes(id)) {
      this.selectedHearings = [...this.selectedHearings.filter(it => it !== id)];
    } else {
      this.selectedHearings = [...this.selectedHearings, id];
    }
  }

  @action.bound
  public async createNewQuestionnaire(title: string) {
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingAdminApi.addHearingSet({
        title: trimSpace(title),
        status: 'draft',
        organization_id,
      });
      history.push(`${routes.management.questionnaireDetail}/${data.id}`);
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.createHearingSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.createHearingError'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async changeHearingStatus(status: string, id?: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const idList = id ? [id] : this.selectedHearings;
      await this.hearingAdminApi.changeHearingStatus({
        status,
        idList,
        confirm: true,
        organization_id,
      });
      this.isLoading = true;
      this.selectedHearings = [];
      if (status === 'draft' || status === 'published') {
        this.pushFlashMessages({
          content: i18n.t('admin.productContent.messages.changeStatusSuccess'),
          status: 'success',
        });
        this.hearingList = this.hearingList.map(hearing => {
          if (idList.includes(hearing.id)) {
            return { ...hearing, status };
          }
          return hearing;
        });
      } else {
        this.pushFlashMessages({
          content: i18n.t('admin.common.messages.archiveSuccess', {
            item: i18n.t('common.actionTarget.hearings'),
          }),
          status: 'success',
        });
        this.getHearingList();
      }
    } catch (error: any) {
      if (error?.message.includes('422')) {
        this.pushFlashMessages({
          content:
            status === 'published'
              ? i18n.t('admin.hearingSet.messages.statusValidationError')
              : i18n.t('admin.common.messages.updateStatusFailed'),
          status: 'error',
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  // Actions for archived heairng list
  @action.bound
  public async getArchivedHearings(page = 1, sort = 'desc', order = 'updated_at') {
    this.isLoading = true;
    this.resetHearingList();
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const data = await this.archiveAdminApi.getArchiveList({
        panel: 'hearings',
        sort,
        order,
        page,
        organization_id,
      });

      const { hearing_sets, total_hearing_sets, hearing_sets_page } = data.data;
      this.hearingList = hearing_sets;
      this.hearingListMeta = {
        ...this.hearingListMeta,
        total: total_hearing_sets,
        page: hearing_sets_page,
      };
    } catch (error: any) {
      this.errors = error;
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async handleRestoreData(id?: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const { sort, order, page } = this.hearingListMeta;
      const listId = id ? [id] : [...this.selectedHearings];
      this.isLoading = true;
      await this.archiveAdminApi.restoreData({
        field: 'hearing_set_ids',
        listId,
        organization_id,
      });
      this.getArchivedHearings(page, sort, order);

      this.pushFlashMessages({
        content: i18n.t('admin.common.messages.restoreSuccess', {
          item: i18n.t(`common.actionTarget.hearings`),
        }),
        status: 'success',
      });
      this.isSelectMode = false;
      this.selectedHearings = [];
    } catch (error: any) {
      this.errors = error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default HearingListStore;
