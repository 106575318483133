import AppointmentStore from './AppointmentStore';
import SalesProductListStore from './SalesProductListStore';
import AppStore from './AppStore';
import HearingLinkStore from './HearingLinkStore';
import SalesHearingListStore from './SalesHearingListStore';
import HearingPlayStore from './HearingPlayStore';
import EmbeddingLinkStore from './EmbeddingLinkStore';
import ClientStore from './ClientStore';

//Admin
import ProductAdminStore from './Admin/ProductAdminStore';
import SettingLogoStore from './Admin/SettingLogoStore';
import SettingSlackStore from './Admin/SettingSlackStore';
import HearingListStore from './Admin/HearingListStore';
import HearingDetailStore from './Admin/HearingDetailStore';
import PreviewHearingStore from './Admin/PreviewHearingStore';
import PlaylistStore from './Admin/PlaylistStore';
import PlaylistDetailStore from './Admin/PlaylistDetailStore';

// Services
import HttpClient from 'adapter/HTTPClient';
import AuthenticationApi from 'services/AuthenticationApi';
import UserApi from 'services/UserApi';
import OrganizationApi from 'services/OrganizationApi';
import AppointmentApi from 'services/AppointmentApi';
import HearingSetApi from 'services/HearingSetApi';
import ProductContentApi from 'services/ProductContentApi';
import HearingLinkApi from 'services/HearingLinkApi';
import UserAnswerApi from 'services/UserAnswerApi';
import EmbeddingLinkApi from 'services/EmbeddingLinkApi';
import ClientApi from 'services/ClientApi';
import QuestionSetApi from 'services/Admin/QuestionSetApi';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import ProductAdminApi from 'services/Admin/ProductAdminApi';
import ArchiveAdminApi from 'services/Admin/ArchiveAdminApi';
import SettingApi from 'services/Admin/SettingApi';
import DataPlatformApi from 'services/DataPlatformApi';
import ClientListStore from './ClientStore';
import SettingHubSpotStore from './Admin/SettingHubSpotStore';
import PlaylistApi from 'services/Admin/PlaylistApi';

const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const KAIZEN_API_URL: string | undefined = process.env.REACT_APP_KAIZEN_API_URL;
const DP_API_URL: string | undefined = process.env.REACT_APP_DP_API_URL;

const authApiHttpClient = new HttpClient({ baseURL: KAIZEN_API_URL });
const dataPlatformApiHttpClient = new HttpClient({ baseURL: DP_API_URL });

const httpClient = new HttpClient({ baseURL: API_URL });

const userApi = new UserApi({
  httpClient,
});
const organizationApi = new OrganizationApi({
  httpClient,
});
const authenticationApi = new AuthenticationApi({ httpClient: authApiHttpClient });

const appointmentApi = new AppointmentApi({
  httpClient,
});
const hearingSetApi = new HearingSetApi({
  httpClient,
});
const productContentApi = new ProductContentApi({
  httpClient,
});
const hearingLinkApi = new HearingLinkApi({
  httpClient,
});
const playlistApi = new PlaylistApi({ httpClient });
const userAnswerApi = new UserAnswerApi({ httpClient });
const embeddingLinkApi = new EmbeddingLinkApi({ httpClient });

const questionSetApi = new QuestionSetApi({ httpClient });
const hearingAdminApi = new HearingAdminApi({ httpClient });
const productAdminApi = new ProductAdminApi({ httpClient });
const archiveAdminApi = new ArchiveAdminApi({ httpClient });
const settingApi = new SettingApi({ httpClient });
const clientApi = new ClientApi({ httpClient });

const dataPlatformApi = new DataPlatformApi({ httpClient: dataPlatformApiHttpClient });

export class RootStore {
  public appointmentStore: AppointmentStore;
  public salesProductListStore: SalesProductListStore;
  public appStore: AppStore;
  public hearingLinkStore: HearingLinkStore;
  public salesHearingListStore: SalesHearingListStore;
  public hearingPlayStore: HearingPlayStore;
  public embeddingLinkStore: EmbeddingLinkStore;
  public clientStore: ClientListStore;
  //Admin
  public productAdminStore: ProductAdminStore;
  public settingLogoStore: SettingLogoStore;
  public settingSlackStore: SettingSlackStore;
  public settingHubSpotStore: SettingHubSpotStore;
  public adminHearingListStore: HearingListStore;
  public hearingDetailStore: HearingDetailStore;
  public previewHearingStore: PreviewHearingStore;
  public playlistStore: PlaylistStore;
  public playlistDetailStore: PlaylistDetailStore;

  constructor() {
    this.appStore = new AppStore({ rootStore: this, userApi, organizationApi, authenticationApi });
    this.appointmentStore = new AppointmentStore({
      rootStore: this,
      appointmentApi,
      userAnswerApi,
    });
    this.hearingLinkStore = new HearingLinkStore({
      rootStore: this,
      hearingLinkApi,
      userAnswerApi,
      clientApi,
      hearingSetApi,
      productContentApi,
      playlistApi,
    });
    this.salesProductListStore = new SalesProductListStore({ rootStore: this, productContentApi });
    this.salesHearingListStore = new SalesHearingListStore({
      rootStore: this,
      hearingSetApi,
    });
    this.hearingPlayStore = new HearingPlayStore({
      rootStore: this,
      hearingSetApi,
      productContentApi,
      appointmentApi,
    });
    this.embeddingLinkStore = new EmbeddingLinkStore({
      rootStore: this,
      embeddingLinkApi,
      dataPlatformApi,
      hearingSetApi,
      playlistApi,
    });
    this.clientStore = new ClientStore({
      rootStore: this,
      clientApi,
    });

    // Admin
    this.productAdminStore = new ProductAdminStore({
      rootStore: this,
      productAdminApi,
      hearingAdminApi,
      archiveAdminApi,
    });
    this.settingLogoStore = new SettingLogoStore({ rootStore: this, settingApi });
    this.settingSlackStore = new SettingSlackStore({ rootStore: this, settingApi });
    this.settingHubSpotStore = new SettingHubSpotStore({ rootStore: this, settingApi });
    this.adminHearingListStore = new HearingListStore({
      rootStore: this,
      hearingAdminApi,
      archiveAdminApi,
    });
    this.hearingDetailStore = new HearingDetailStore({
      rootStore: this,
      hearingAdminApi,
      questionSetApi,
    });
    this.previewHearingStore = new PreviewHearingStore({
      rootStore: this,
      hearingAdminApi,
    });
    this.playlistStore = new PlaylistStore({
      rootStore: this,
      playlistApi,
      productContentApi,
    });
    this.playlistDetailStore = new PlaylistDetailStore({
      rootStore: this,
      playlistApi,
    });
  }
}

const store: RootStore = new RootStore();

export default store;
